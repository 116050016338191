export function findInsertIndex(arr:  HTMLElement[], val: HTMLDivElement, getIndexOfElement: (val1: HTMLElement) => number): number {
	// Lower and upper bounds
	let start = 0;
	let end = arr.length - 1;
	const indexOfElement = getIndexOfElement(val);

	// Traverse the search space
	while (start <= end) {
		const mid = Math.floor((start + end) / 2);
		const index = getIndexOfElement(arr[mid]);

		// If K is found
		if (index == indexOfElement)
			return mid;
		else if (index < indexOfElement)
			start = mid + 1;
		else
			end = mid - 1;
	}

	// Return insert position
	return end + 1;
}
