﻿export interface IInitCountDownOptions {
	readonly showDays?: boolean;
	readonly onCountDownFinish?: () => void;
	readonly daysLabel?: string;
	readonly dayLabel?: string;
}

export function initCountDown(containerEl: HTMLElement, el: HTMLElement, countDownToDate: string, opts: IInitCountDownOptions ) {
	const {
		showDays,
		onCountDownFinish,
		daysLabel,
		dayLabel
	} = opts;

	const toDate = (input: string): Date => {
		const dateString = input.split(' ')[0];
		const timeString = input.split(' ')[1];
		const day = parseInt(dateString.split('/')[0], 10);
		const month = parseInt(dateString.split('/')[1], 10) - 1;
		const year = parseInt(dateString.split('/')[2], 10);
		const hour = parseInt(timeString.split(':')[0], 10);
		const minutes = parseInt(timeString.split(':')[1], 10);
		const seconds = parseInt(timeString.split(':')[2], 10);
		return new Date(year, month, day, hour, minutes, seconds, 0);
	};

	const pad = (num: number, size: number) => {
		let temp = num.toString();
		while (temp.length < size) temp = "0" + temp;
		return temp;
	};

	const countDownTime = toDate(countDownToDate).getTime();

	const countDown = () => {
		const now = new Date().getTime();
		const timeLeft = countDownTime - now;
		const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
		let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

		if (timeLeft > 0) {
			if (!showDays || !daysLabel || !dayLabel) {
				hours = hours + (days * 24);
				el.innerHTML = pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
			} else {
				if (days > 1)
					el.innerHTML = days + ' ' + daysLabel + ' ' + pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
				else if (days === 1)
					el.innerHTML = days + ' ' + dayLabel + ' ' + pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
				else
					el.innerHTML = pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
			}
		} else {
			if (onCountDownFinish) onCountDownFinish();
			clearInterval(interval);
		}
	};

	const interval = setInterval(countDown, 1000);
}
