﻿import {
	expressAddClass,
	expressEventListener,
	expressQuerySelector,
	getParentWithClassName,
	hasClass,
	expressQuerySelectorAll,
	expressRemoveClass
} from "../common/html";
import { compositeDisposable, IDisposable } from "../utils/disposable";
import { isTouchDevice } from "../common/device";
import { debounce } from "../utils/debounce";

export interface ICreateNavigationDesktopDependencies {
	readonly loadDesktopMenuItemAsync: (firstLevel: number, secondLevel: number) => Promise<string>;
}

export function initNavigationDesktop(containerEl: HTMLElement, deps: ICreateNavigationDesktopDependencies) {
	const firstLevelMenuEls = expressQuerySelectorAll<HTMLElement>(containerEl, ".technical-desktop-first-level-menu-item");
	const { loadDesktopMenuItemAsync } = deps;
	const loadedMenuItemsKeys: number[][] = [];
	let disposable: IDisposable;
	const firstLevelActiveClass = "o-menu-first-level__button--active";
	const secondLevelActiveClass = "o-menu-second-level__list-item--active";

	const closeMenu = (e: MouseEvent) => {
		e.stopPropagation();
		const clickedItem = e.currentTarget as HTMLElement;
		if (getParentWithClassName(clickedItem, 'o-menu-second-level__list')) return;
		firstLevelMenuEls.forEach(el => closeFirstLevelMenuItemAndResetChildMenus(el));
	};

	const closeFirstLevelMenuItemAndResetChildMenus = (menuItem: HTMLElement) => {
		const buttonEL = expressQuerySelector<HTMLElement>(menuItem, '.technical-level1-button', true);
		expressRemoveClass(buttonEL, firstLevelActiveClass);
		closeAllSecondLevelMenuItemsAndResetChildMenus(menuItem);
	};

	const closeAllSecondLevelMenuItemsAndResetChildMenus = (menuItem: HTMLElement) => {
		const secondLevelMenuEls = expressQuerySelectorAll<HTMLElement>(menuItem, '.technical-desktop-menu-second');
		secondLevelMenuEls.forEach(secondLevelMenuEl => {
			expressAddClass(secondLevelMenuEl, 'u-hide');
			closeAllThirdLevelMenuItemsAndResetChildMenus(secondLevelMenuEl);
		});
	};

	const closeAllThirdLevelMenuItemsAndResetChildMenus = (menuItem: HTMLElement) => {
		const allThirdLevelMenuItems = expressQuerySelectorAll<HTMLElement>(menuItem, '.technical-desktop-second-level-menu-item');
		allThirdLevelMenuItems.forEach(el => {
			const contentEl = expressQuerySelector<HTMLElement>(el, '.technical-desktop-second-level-content', false);
			contentEl && expressAddClass(contentEl, 'u-hide');
			expressRemoveClass(el, secondLevelActiveClass);
		});
	};

	const getOrLoadFirstLevelMenuItem = (e: MouseEvent) => {
		e.stopPropagation();
		if (e.type.toLowerCase() === "mouseenter" && isTouchDevice()) return;
		const menuItem = e.currentTarget as HTMLElement;
		const secondLevelMenuEl = expressQuerySelector<HTMLElement>(menuItem, '.technical-desktop-menu-second', true);
		const buttonEL = expressQuerySelector<HTMLElement>(menuItem, '.technical-level1-button', true);

		const closeMenuOnMouseOut = (e: MouseEvent) => {
			const menuItem = e.currentTarget as HTMLElement;
			closeFirstLevelMenuItemAndResetChildMenus(menuItem);
		};

		// First hide all other items
		const allSecondLevelMenuItems = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-desktop-menu-second');
		allSecondLevelMenuItems.forEach(el => closeAllSecondLevelMenuItemsAndResetChildMenus(el));

		// Set selected item and fetch content if needed
		if (expressQuerySelectorAll(secondLevelMenuEl, ".o-menu-second-level__list.skeleton-loading").length === 1) {
			const itemKey = parseInt(menuItem.getAttribute("data-menu-item") || "", 10);
			const alreadyLoaded = itemKey in loadedMenuItemsKeys;
			if (!alreadyLoaded) {
				loadedMenuItemsKeys[itemKey] = [];
				expressAddClass(buttonEL, firstLevelActiveClass);
				loadDesktopMenuItemAsync(itemKey, -1).then(res => {
					secondLevelMenuEl.innerHTML = ""; // this removes the skeleton loading
					secondLevelMenuEl.insertAdjacentHTML('beforeend', res);
					window.addEventListener('resize', () => {
						debounce(() => {
							if (!secondLevelMenuEl) return;
							secondLevelMenuEl.style.top = Math.floor(expressQuerySelector<HTMLElement>(document, '.technical-header', true).clientHeight) + 'px';
						}, 250);
					});
					secondLevelMenuEl.style.top = Math.floor(expressQuerySelector<HTMLElement>(document, '.technical-header', true).clientHeight) + 'px';
					const buttonEls = expressQuerySelectorAll(secondLevelMenuEl, '.technical-third-link-item');
					if (buttonEls.length && buttonEls.every(x => x.nodeName.toLowerCase() === 'a')) {
						const subMenuListEl = expressQuerySelector<HTMLElement>(secondLevelMenuEl, '.o-menu-second-level__list', true);
						subMenuListEl.style.left = (menuItem.offsetLeft - containerEl.offsetLeft) + "px";
					}
					initSecondLevelListeners();
					// To prevent opening the menu, when the visitor already moved to another menu item, we check the active class again
					if (hasClass(buttonEL, firstLevelActiveClass)) expressRemoveClass(secondLevelMenuEl, 'u-hide');
				});
				disposable.add(compositeDisposable(firstLevelMenuEls.map(el => expressEventListener(el, 'mouseleave', closeMenuOnMouseOut))));
				disposable.add(expressEventListener(secondLevelMenuEl, 'click', closeMenu));
			}
		} else {
			if (e.type.toLowerCase() === "click" && isTouchDevice()) {
				if (hasClass(buttonEL, firstLevelActiveClass)) {
					expressRemoveClass(buttonEL, firstLevelActiveClass);
					expressAddClass(secondLevelMenuEl, 'u-hide');
					closeAllThirdLevelMenuItemsAndResetChildMenus(secondLevelMenuEl);
				} else {
					expressAddClass(buttonEL, firstLevelActiveClass);
					expressRemoveClass(secondLevelMenuEl, 'u-hide');
				}
			} else {
				expressAddClass(buttonEL, firstLevelActiveClass);
				expressRemoveClass(secondLevelMenuEl, 'u-hide');
			}
		}
	};

	const initFirstLevelListeners = () => {
		disposable = compositeDisposable(firstLevelMenuEls.map(el => expressEventListener(el, 'mouseenter', getOrLoadFirstLevelMenuItem)));
		disposable.add(compositeDisposable(firstLevelMenuEls.map(el => expressEventListener(el, 'click', getOrLoadFirstLevelMenuItem))));
	};

	const getOrLoadSecondLevelMenuItem = (e: MouseEvent) => {
		e.stopPropagation();
		if (e.type.toLowerCase() === "mouseenter" && isTouchDevice()) return;
		const menuItem = e.currentTarget as HTMLElement;
		if (hasClass(menuItem, secondLevelActiveClass)) return;
		const parent = menuItem.closest(".technical-desktop-first-level-menu-item") as HTMLElement;

		// First hide all other items
		closeAllThirdLevelMenuItemsAndResetChildMenus(parent);

		// Set selected item and fetch content if needed
		expressAddClass(menuItem, secondLevelActiveClass);
		const contentEl = expressQuerySelector<HTMLElement>(menuItem, '.technical-desktop-second-level-content', false);
		contentEl && expressRemoveClass(contentEl, 'u-hide');

		const buttonEl = expressQuerySelector<HTMLElement>(menuItem, '.technical-third-link-item', true);
		if (buttonEl.nodeName.toLowerCase() === "button") {
			const firstLevelIndex = parseInt(parent.getAttribute("data-menu-item") || "", 10);
			const secondLevelIndex = parseInt(menuItem.getAttribute("data-menu-item") || "", 10);
			const alreadyLoaded = loadedMenuItemsKeys[firstLevelIndex].includes(secondLevelIndex);

			if (!alreadyLoaded) {
				loadedMenuItemsKeys[firstLevelIndex].push(secondLevelIndex);
				loadDesktopMenuItemAsync(firstLevelIndex, secondLevelIndex).then(res => {
					menuItem.querySelector(".technical-desktop-second-level-content").innerHTML = res;
				});
			}
		}
	};

	const initSecondLevelListeners = () => {
		const secondLevelMenuEls = expressQuerySelectorAll(containerEl, ".technical-desktop-second-level-menu-item");
		disposable.add(compositeDisposable(secondLevelMenuEls.map(el => expressEventListener(el, 'mouseenter', getOrLoadSecondLevelMenuItem))));
		disposable.add(compositeDisposable(secondLevelMenuEls.map(el => expressEventListener(el, 'click', getOrLoadSecondLevelMenuItem))));
	};

	// init handlers on load
	initFirstLevelListeners();
	const disableHoverMenuItems = () => disposable && disposable.dispose();

	return { dispose: disableHoverMenuItems };
}
