﻿import { expressEventListener, expressQuerySelector } from "../common/html";
import { createButton } from "./button";
import { createFormInput, createPasswordFormInput, createSelectBox, IFormInput, ISelectBox } from "./form-input";
import { IDisposable } from "../utils/disposable";


export interface IInitFormDependencies {
	readonly alternativeButton?: HTMLButtonElement;
	readonly buttonSelector?: string;
	readonly onSubmitForm: (data: { [inputName: string]: string }) => Promise<void>;
	readonly submitOnEnter?: boolean;
	readonly validatorOptions?: ICreateFromValidatorOptions;
}

// provide custom behaviour
export interface ICreateFromValidatorOptions {
	readonly classTo?: string; // class of the parent element where the error/success class is added
	readonly errorClass?: string;
	readonly successClass?: string;
	readonly errorTextParent?: string; // class of the parent element where error text element is appended
	readonly errorTextTag?: string; // type of element to create for the error text
	readonly errorTextClass?: string; // class of the error text element
	readonly onValidate?: () => void;
}

export interface IFormValidator {
	readonly validate: () => boolean;
	readonly reset: () => void;
}

export interface IForm {
	readonly dispose: () => void;
	readonly disableForm: (disable: boolean) => void;
	readonly disableInput: (name: string) => void;
	readonly validate: () => boolean;
	readonly setErrors: (clear?: boolean, ...errors: { name: string,  error: string }[]) => void;
	readonly clearInputs: (clearValue?: boolean, ...names: string[]) => void;
	readonly clearAll: () => void;
	readonly setValues: (...values: { name: string, value: string }[]) => void;
	readonly hideInput: (name: string) => void;
	readonly inputs: { [p: string]: ISelectBox | IFormInput }
}

export function initForm(formEl: HTMLFormElement, deps: IInitFormDependencies) {
	const {
		alternativeButton,
		onSubmitForm,
		buttonSelector,
		submitOnEnter,
		validatorOptions,
	} = deps;
	const submitButtonEl = alternativeButton || expressQuerySelector<HTMLButtonElement>(formEl, buttonSelector || '.m-form__button-continue', true);
	const validator = createFormValidator(formEl, validatorOptions);
	let onKeyUpEvent: IDisposable;
	const formElements = [].slice.call(formEl.elements);
	const inputs: { [name: string]: ISelectBox | IFormInput; } = {};

	formElements
		.filter(el => !el.matches("button"))
		.forEach(el => {
			// The element can be : INPUT (regular or password), TEXTAREA, SELECT,
			let input: ISelectBox | IFormInput | null = null;
			const email = el.attributes.getNamedItem("email");
			if ((email && email.value === "email") || el.type === "email")
				el.maxLength = 100;

			if (el.classList.contains('a-form-input__select'))
				input = createSelectBox(el.parentElement!);
			else if (el.classList.contains('a-form-input__input'))
				input = el.type === "password" ? createPasswordFormInput(el.parentElement!) : createFormInput(el.parentElement && el.parentElement.parentElement && el.parentElement.parentElement.classList.contains('a-form-input') ? el.parentElement.parentElement! : el.parentElement!);

			// If not those 3, it's something else -> leave it alone
			if (input)
				inputs[input.inputEl.id.toLowerCase()] = input;
		});

	const getInputs = () => Object.keys(inputs).map(key => inputs[key]);

	const clearAll = (clearValues = false) => {
		getInputs()
			.map(input => input.clear(clearValues));
	};

	const resetValidation = () => {
		clearAll();
		validator.reset();
	};

	const validate = () => {
		resetValidation();
		return validator.validate();
	};

	const formData = () => {
		const data: { [name: string]: string } = {};
		Object.keys(inputs).forEach(name => data[name] = inputs[name].getValue().toString() || "");
		return data;
	};

	const onSubmit = () => {
		return validate() ? onSubmitForm(formData()) : Promise.resolve();
	};

	const submitButton = createButton(submitButtonEl, onSubmit);

	if (submitOnEnter && submitButtonEl && submitButtonEl.type.toLowerCase() !== "submit") {
		onKeyUpEvent = expressEventListener(
			formEl,
			'keypress',
			(ev: KeyboardEvent) => {
				if (ev.key === "Enter" || ev.keyCode === 13) {
					ev.preventDefault();
					onSubmit();
				}
			}
		);
	}

	const getInputByName: (name: string) => ISelectBox | IFormInput | undefined =
		(name: string) => inputs[name.toLowerCase()];

	const dispose = () => {
		clearAll(true);
		submitButton.dispose();
		onKeyUpEvent && onKeyUpEvent.dispose();
	};

	const toggleSubmitButton = (enable: boolean) => enable ? submitButton.enable() : submitButton.disable();

	const setError = (name: string, error: string, clear = false) => {
		const input = getInputByName(name);
		if (input) input.setError(error, clear);
	};

	const setErrors = (clear = false, ...errors: { name: string, error: string }[]) => {
		errors.forEach(e => setError(e.name, e.error, clear));
		if (errors.length) {
			const input = getInputByName(errors[0].name);
			if (input) input.inputEl.parentElement!.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	const clearInput = (name: string, clearValue = false) => {
		const input = getInputByName(name);
		if (input) input.clear(clearValue);
	};

	const clearInputs = (clearValue = false, ...names: string[]) => names.forEach(name => clearInput(name, clearValue));

	const disableForm = (disable: boolean) => {
		getInputs().map(i => disable ? i.disable() : i.enable());
		resetValidation();
		toggleSubmitButton(!disable);
	};

	const disableInput = (name: string) => {
		const input = getInputByName(name);
		if (input) input.disable();
	};

	const setValue = (name: string, value: string) => {
		const input = getInputByName(name);
		if (input) input.setValue(value);
	};

	const setValues = (...values: { name: string, value: string }[]) =>
		values.filter(v => getInputByName(v.name))
			.forEach(v => setValue(v.name, v.value));

	const hideInput = (name: string) => {
		const input = getInputByName(name);
		if (input) input.hide();
	};

	return {
		dispose,
		disableForm,
		disableInput,
		validate,
		setErrors,
		clearInputs,
		clearAll: () => clearAll(true),
		setValues,
		hideInput,
		inputs
	} as IForm;
}

export function createFormValidator(form: HTMLFormElement, options: ICreateFromValidatorOptions = {}): IFormValidator {

	const {
		classTo = "a-form-input",
		errorClass = "a-form-input--error",
		successClass = "a-form-input--validated",
		errorTextClass = "a-form-input__error-text",
		errorTextParent = "a-form-input",
		errorTextTag = "p",
		onValidate
	} = options;

	const config: config = {
		classTo: classTo,
		errorClass: errorClass,
		successClass: successClass,
		errorTextClass: errorTextClass,
		errorTextParent: errorTextParent,
		errorTextTag: errorTextTag
	};

	const validator = new Pristine(form, config, false);
	const validate = () => {
		if (onValidate) onValidate();
		return validator.validate();
	};

	return {
		validate,
		reset: () => validator.reset()
	};
}
