﻿export function calculateAndSetHeightOfMobileNavigationLevel(mobileNavigationLevel: HTMLElement) {
	mobileNavigationLevel.style.height = '100vh';

	// Calculate height of lvl menu (without browser bars)
	const browserUiBars = mobileNavigationLevel.getBoundingClientRect().height - window.innerHeight;
	const menuItemsLvlPositionTop = document.body.getBoundingClientRect().top - mobileNavigationLevel.getBoundingClientRect().top;

	// Set height of level menu items to correct size calculated
	mobileNavigationLevel.style.height = 'calc(100vh - ' + Math.abs(browserUiBars) + 'px + ' + menuItemsLvlPositionTop + 'px)';
}
