﻿import {getParents} from "../common/html";

export interface ICreateTooltipOptions {
	readonly buttonEl: HTMLElement;
	readonly containerEl: HTMLElement;
	readonly onOpen?: (containerEl: HTMLElement) => void;
	readonly onClose?: () => void;
	readonly iconCloseSelector?: string;
	readonly openClass?: string;
	readonly tooltipSelector?: string;
	readonly toggleIconSelector?: string;
	readonly useCapture?: boolean;
}

export interface IToolTip {
	readonly dispose: () => void;
	readonly closeToolTip: () => void;
}
export function initTooltip(options: ICreateTooltipOptions) {
	const {
		buttonEl,
		containerEl,
		onOpen,
		onClose,
		iconCloseSelector,
		openClass,
		tooltipSelector,
		toggleIconSelector,
		useCapture = false
	} = options;

	const closeIconEl = containerEl.querySelector(iconCloseSelector || ".icon-close");

	const onCloseIconClick = (e: Event) => {
		e.stopPropagation();
		closeToolTip();
	};

	const closeToolTip = () => {
		containerEl.classList.remove(openClass || "is-tooltip-open");
		if (onClose) onClose();
		document.removeEventListener("click", onDocumentClick);
	};

	const openToolTip = () => {
		containerEl.classList.add(openClass || "is-tooltip-open");
		if (onOpen) onOpen(containerEl);
		document.addEventListener("click", onDocumentClick, { capture: useCapture });
	};

	const onButtonClick = (e: MouseEvent) => {
		if (getParents(e.target as HTMLElement, (tooltipSelector || ".technical-price-tooltip")).length < 1)
		{
			containerEl.classList.contains(openClass || "is-tooltip-open")
				? closeToolTip()
				: openToolTip();
		}
	};

	const onDocumentClick = (e: MouseEvent) => {
		const selector = (tooltipSelector || ".technical-price-tooltip") + ", " + (toggleIconSelector || ".technical-price-info-icon");
		if (!(e.target as HTMLElement).closest(selector)) {
			closeToolTip();
		}
	};

	//close tooltip
	if (closeIconEl) closeIconEl.addEventListener("click", onCloseIconClick);

	//open tooltip + function
	buttonEl.addEventListener("click", onButtonClick);

	const dispose = () => {
		buttonEl.removeEventListener("click", onButtonClick);
		document.removeEventListener("click", onDocumentClick);
		if (closeIconEl) closeIconEl.removeEventListener("click", onCloseIconClick);
	};

	return {
		dispose,
		closeToolTip
	} as IToolTip;
}
