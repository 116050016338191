﻿import { expressQuerySelector } from "../common/html";
import { createNewsletterSubscribe } from "./newsletter";
import { createPopup } from "./popup-new";

export function initLeaveIntent() {
	const toggleLeaveIntent = (enable: boolean) => { if (typeof window.enableLeaveIntent !== "undefined") window.enableLeaveIntent = enable; };
	const isLeaveIntentEnabled = (): boolean => window.enableLeaveIntent && window.location.search.indexOf("embed") < 0;
	const triggerLeaveIntentPopup = () => {
		createPopup({
			dialogId: 'leave-intent-dialog',
			view: 'leaveintentdialog'
		}).then(popup => {
			if (!popup.popup) return;
			popup.openAsync()
				.then(_ => createNewsletterSubscribe(expressQuerySelector(popup.popup, '.technical-newsletter-subscribe', true)));
		});
	};
	const init = (forceEnable?: boolean) => {
		if (!isLeaveIntentEnabled() && !forceEnable) return;

		try {
			ouibounce(false, {
				sensitivity: 20,
				aggressive: false,
				sitewide: true,
				timer: 1000,
				delay: 100,
				callback: triggerLeaveIntentPopup,
				cookieExpire: 1,
				canFire: isLeaveIntentEnabled
			});
		} catch (exc) {
			// Adblocker probably blocked ouiBounce from loading.
		}
	};

	init(false);

	return {
		EnableLeaveIntentPopup: () => init(true),
		EnableLeaveIntent: () => toggleLeaveIntent(true),
		DisableLeaveIntent: () => toggleLeaveIntent(false)
	};
}
