﻿export function getCookie(cName: string): string {
	const allCookies = document.cookie;
	if (allCookies.length > 0) {
		let cStart = allCookies.indexOf(cName + "=");
		if (cStart !== -1) {
			cStart = cStart + cName.length + 1;
			let cEnd = allCookies.indexOf(";", cStart);
			if (cEnd === -1) cEnd = allCookies.length;
			return unescape(allCookies.substring(cStart, cEnd));
		}
	}
	return "";
}
export function writeCookie(cName: string, cValue: string, extraDays: number) {
	const d = new Date();
	d.setTime(d.getTime() + (extraDays * 24 * 60 * 60 * 1000));
	const expires = "expires=" + d.toUTCString();
	document.cookie = encodeURIComponent(cName) + "=" + encodeURIComponent(cValue) + ";" + expires + "; path=/";
}

export function writeCookieThatExpiresAtTheEndOfTheDay(cName: string, cValue) {
	const d = new Date();
	const midnight = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
	const expires = "expires=" + midnight.toUTCString();
	document.cookie = encodeURIComponent(cName) + "=" + encodeURIComponent(cValue) + ";" + expires + "; path=/";
}
