import {getHtmlRequest} from "../utils/fetch";
import {addParameter, updateParameter} from "../utils/querystring";
const basePath = "/apicore/componentapi/";

export function getComponentContent(
	component: string,
	contentKey: string,
	epc = "",
	isProspect = false,
	themeKey = '',
	pageTemplate = '',
	pageName = '',
	index= "0") : Promise<string> {
	let path = basePath + component;
	if (contentKey) path = addParameter("contentKey", contentKey, path);
	if (epc) path = addParameter("epc", epc, path);
	path = addParameter("isProspect", isProspect+'', path);
	if (themeKey) path = addParameter("theme", themeKey, path);
	if (pageTemplate) path = addParameter("pageTemplate", pageTemplate, path);
	if (pageName) path = addParameter("pageName", pageName, path);
	path = addParameter("index", index, path);
	return getHtmlRequest(
		path,
		{ headers: new Headers({'Content-type': 'application/json; charset=utf-8'}) })
		.then() as Promise<string>;
}
