﻿import {expressQuerySelectorAll} from "../common/html";
import {getInlineSvgsAsync} from "../utils/icons";

export function createUsps(containerEl: HTMLElement) {
	const iconEls = expressQuerySelectorAll(containerEl, '.m-usp__icon');
	const icons = {};
	getInlineSvgsAsync(
		iconEls
			.filter(el => !!el.getAttribute('data-icon-url'))
			.map(el => el.getAttribute('data-icon-url')!)
	)
		.then(
			res => {
				res.forEach(icon => icons[icon.url] = icon.svg);
				iconEls.forEach(el => {
					const icon = icons[el.getAttribute('data-icon-url')!.replace("Icon.", "").toLowerCase()];
					icon && el.insertAdjacentHTML("beforeend", icon);
				});
			}
		);
}
