﻿export function escapeForJavascript(str: string | undefined): string {
	if (!str) return "";
	str = str.replace(/'/g, "");
	str = str.replace(/"/g, "");
	str = str.replace(/\\/g, "");
	str = str.replace(/[\n\r]/g, "\\\\n");
	return str;
}

export function capitalizeFirstLetterOfWords(input: string): string {
	const words = input.split(" ").filter(element => element);

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substring(1).toLowerCase();
	}

	return words.join(" ");
}

export function onlyLetters(input: string): boolean {
	return Boolean(input.match(/^[A-Za-z]*$/));
}

export function onlyNumber(input: string): boolean {
	return Boolean(input.match(/^[0-9]*$/));
}

export function toUrlHash(input: string): string {
	return escapeForJavascript(input).replace(' ', '-').toLowerCase();
}

export function stringToBase64(inputString: string): string {
	const bytes = new TextEncoder().encode(inputString);
	const binString = Array.from(bytes, (byte) =>
		String.fromCodePoint(byte),
	).join("");
	return window.btoa(binString);
}

export function base64ToString(inputBase64: string): string {
	const binString = window.atob(inputBase64);
	const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
	return new TextDecoder().decode(bytes);
}
