﻿import {getParentWithClassName, expressQuerySelectorAll} from "../common/html";

export function stopSkeletonLoading() {
	expressQuerySelectorAll<HTMLImageElement>(document, ".skeleton-loading img").forEach(img => {
		const removeSkeleton = () => {
			const parent = getParentWithClassName(img, "skeleton-loading");
			if (parent) parent.classList.remove("skeleton-loading");
		};

		if (img.complete) {
			removeSkeleton();
		} else {
			img.addEventListener("load", () => { removeSkeleton(); });
		}
	});
}
