import {createPopup, IPopup} from "./popup-new";
export interface IShowContentDialogDependencies {
	readonly dialogId: string;
	readonly label?: string;
	readonly title?: string;
	readonly cssClass?: string;
	readonly addTitleCssClass?: boolean;
	readonly extraPopupCss?: string;
	readonly openFunction?: (popupEl: HTMLElement) => void;	
	readonly okButtonKey?: string;
	readonly onClose? : (popupEl?: HTMLElement) => void;
}

export function ShowContentDialog(deps: IShowContentDialogDependencies): Promise<IPopup> {
	const { label, title, dialogId, cssClass, openFunction, okButtonKey, onClose, addTitleCssClass, extraPopupCss } = deps;
	if (!dialogId) return;

	return createPopup({
		dialogId: dialogId,
		view: 'GenericPopup',
		data: {
			BodyLabel: label?.startsWith('Label.') ? label : `Label.${label}`,
			TitleLabel: title?.startsWith('Label.') ? title : `Label.${title}`,
			ExtraPopupCss: extraPopupCss,
			TitleCssClass: addTitleCssClass ? 'a-typography__no-decoration technical-popup-title' : '',
			OkButtonLabel: okButtonKey
		},
		cssClass: cssClass,
		closeOnClickOutsidePopup: true,
		forceRefresh: true,
		onClose
	}).then(popup => {
		if (!popup.popup) return;
		popup.openAsync();
		if (openFunction)
			openFunction(popup.popup);
		return popup;
	});
}
