﻿export function getFullQuerystring(url?: string): string {
	url = url || window.location.href;
	const pos = url.indexOf("?");
	if (pos > -1) {
		return url.substring(pos + 1);
	} else {
		return url;
	}
}

export function getParameters(url?: string): { [key: string]: string } {
	const queryString = getFullQuerystring(url);
	// remove hash parameter and split querystring param
	const pairs = queryString.split('#')[0].split("&");
	const params: { [key: string]: string } = {};
	for (const pair of pairs) {
		const [key, value] = pair.split("=");
		params[key.toLowerCase()] = value !== undefined ? value : "true"; // Providing just the key means key=true
	}
	return params;
}

export function getParameter(key: string, url?: string, required?: boolean): string;
export function getParameter(key: string, url?: string, required = false): string {
	const queryStringParam = getParameters(url)[key ? key.toLowerCase() : key];
	if (required && !queryStringParam) throw new Error(`No queryStringParam for Required key: ${key}`);
	return queryStringParam;
}

export function updateParameter(key: string, value: string, url?: string): string {
	url = url || window.location.href;

	let newAdditionalURL = "";
	let tempArray = url.split("#");
	const hash = tempArray[1] ? tempArray[1] : '';
	const additionalURL = tempArray[0] ? tempArray[0].split("?")[1] : '';
	const baseURL = tempArray[0] ? tempArray[0].split("?")[0] : '';
	let temp = "";
	if (additionalURL) {
		tempArray = additionalURL.split("&");
		for (let i = 0; i < tempArray.length; i++) {
			if (tempArray[i].split('=')[0] !== key) {
				newAdditionalURL += temp + tempArray[i];
				temp = "&";
			}
		}
	}

	let result = temp;
	if (value)
		result += "" + key + "=" + value;
	else
		result = result.substring(0, result.length - 1);

	if (!newAdditionalURL && !result)
		return baseURL + (hash ? '#' + hash : '');

	return baseURL + "?" + newAdditionalURL + result + (hash ? '#' + hash : '');
}

export function addParameter(key: string, value: string, url?: string): string {
	url = url || window.location.href;
	if (url.indexOf(key) > 0) return updateParameter(key, value, url);
	if (url.indexOf('?') > 0) {
		url += '&' + key + "=" + value;
	} else {
		url += '?' + key + "=" + value;
	}
	return url;
}

export function objectToQueryString(o: object): string {
	return '?' + Object.keys(o).map(function (key) {
		return key + '=' + o[key];
	}).join('&');
}

export function queryRemoveKeys(queryKeys: string[]): string {
	let url = window.location.href;
	const a = url.indexOf("?");
	if (a < 0) return url; // case when no query parameters

	queryKeys.forEach(x => {
		url = updateParameter(x ? x.toLowerCase() : x, '', url);
	});

	return url;
}

export function queryRemove(): string {
	const url = window.location.href;
	const a = url.indexOf("?");
	if (a < 0) return url; // case when no query parameters
	const b = url.substring(a);
	return url.replace(b, "");
}
