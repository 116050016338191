﻿import {expressQuerySelector, expressQuerySelectorAll, hasClass, isElementVisibleInViewPort} from '../common/html';
import { createClickableElement } from './button';

export interface ICreateCollapsableOptions {
	readonly singleOpen?: boolean;
	readonly swapTitleDataAttribute?: string;
}

export interface ICreateCollapsableDependencies {
	onInit?: (container: HTMLElement) => Promise<void>;
	onBeforeOpen?: (container: HTMLElement) => Promise<void>;
	readonly onBeforeClose?: (container: HTMLElement) => Promise<void>;
	readonly titleElSelector?: string;
	readonly scrollIntoViewAfterOpen: boolean;
	readonly contentSelector?: string;
	readonly openClass?: string;
}

export interface ICollapsibleContentRequest {
	readonly type: string;
}

export interface ICollapsable {
	dispose(): void;
	show(): void;
	close(): void;
	hide(): void;
	open(scrollIntoView?: boolean): void;
	element: HTMLElement;
}

export function createCollapsable(container: HTMLElement, deps?: ICreateCollapsableDependencies, opts?: ICreateCollapsableOptions): ICollapsable {
	const { singleOpen, swapTitleDataAttribute } = opts || {};
	const { onInit, onBeforeOpen, onBeforeClose, titleElSelector, scrollIntoViewAfterOpen, contentSelector, openClass } = deps || {};
	const OPEN_CLASS = openClass ? openClass : "is-collapse-open";
	const titleEl = expressQuerySelector<HTMLElement>(container, titleElSelector ? titleElSelector : '.technical-collapsable-title', true);
	const contentEl = expressQuerySelector<HTMLElement>(container, contentSelector ? contentSelector : '.technical-collapse-content', false);
	let origTitle: string;
	let collapseTitle: string;
	if (swapTitleDataAttribute) {
		origTitle = titleEl.innerText;
		collapseTitle = swapTitleDataAttribute ? container.getAttribute(swapTitleDataAttribute) : null;
	}
	const closeWithBefore = async () => {
		if (!onBeforeClose) {
			close();
			return Promise.resolve();
		}
		await onBeforeClose(container);
		return close();
	};

	const openWithBefore = async (scrollIntoView = false) => {
		if (!onBeforeOpen) {
			open();
			return Promise.resolve();
		}
		await onBeforeOpen(container);
		return open(scrollIntoView);
	};

	const close = () => {
		if (swapTitleDataAttribute && origTitle && titleEl.innerText !== origTitle) {
			titleEl.innerText = origTitle;
		}
		container.classList.remove(OPEN_CLASS);
	};
	const open = (shouldScrollIntoView?: boolean) => {
		if (singleOpen) {
			const collapsibleEls = expressQuerySelectorAll<HTMLElement>(container.parentElement, '.m-collapsable');
			collapsibleEls.forEach(el => el.classList.remove(OPEN_CLASS));
		}
		if (swapTitleDataAttribute && titleEl.innerText !== collapseTitle) {
			titleEl.innerText = collapseTitle;
		}
		container.classList.add(OPEN_CLASS);
		if ((scrollIntoViewAfterOpen && !isElementVisibleInViewPort(contentEl)) || shouldScrollIntoView)
			titleEl.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	const isOpen = () => hasClass(container, OPEN_CLASS);

	const onClick = () => !isOpen() ? openWithBefore() : closeWithBefore();

	const button = createClickableElement(titleEl, onClick);

	onInit && onInit(container);

	return {
		dispose: button.dispose,
		show: () => container.classList.remove('u-hide'),
		hide: () => container.classList.add('u-hide'),
		close,
		open: (scrollIntoView?: boolean) => openWithBefore(scrollIntoView),
		element: container
	} as ICollapsable;
}
