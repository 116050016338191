﻿import {getCookie} from "../utils/cookie";

const trackEvent = (category: string, action: string, customParameters?: object) => {
	const dataLayer = (<any>window).dataLayer || [];
	dataLayer.push({
		event: category,
		action: action,
		...customParameters
	});
};

interface IOrderStatusParams {
	orderNumber?: string;
	orderStatus?: string;
	paymentStatus?: string;
	pvc?: string;
	repro?: boolean;
}

export function trackOrderStatusEvent(action: string, partialEventParams: IOrderStatusParams, nrOrdersInAccount = -1, nrItemsInOrder = -1) {
	trackEvent('orderStatus', action, { accountId: getCookie('accid'), nrOrdersInAccount, nrItemsInOrder, ...partialEventParams });
}

export function trackContactPageEvent(action: string) {
	trackEvent('contactPage', action);
}

export function trackSortingOnPop(action: string) {
	trackEvent('sortingOnPOP', action);
}

export function trackComponentEvent(action: string) {
	trackEvent('component', action);
}

export function trackAddressValidationEvent(action: string) {
	trackEvent('addressValidation', action);
}

export function trackFiltersEvent(action: string, eventParams?: object) {
	trackEvent('filters', action, eventParams);
}

export function trackSliderEvent(action: string, eventParams?: object) {
	trackEvent('slider', action, eventParams);
}

export function trackMgmEvent(action: string) {
	trackEvent('MGMProgram', action);
}

export function trackAccountPrefEvent(action: string, eventParams?: object) {
	trackEvent('accountPreferences', action, eventParams);
}

export function ProductEvent(action: string, eventParams?: object) {
	trackEvent('productSelection', action, eventParams);
}

export interface IRecommendationEventParams {
	pageType?: string;
	pageCode?: string;
	pvc?: string;
}
export function trackRecommendationCartEvent(action: string, recommendationLabel?: IRecommendationEventParams) {
	trackEvent('recommendations', action, recommendationLabel);
}

export interface IShopInShopEventParams {
	pvc?: string;
}
export function trackShopInShopEvent(action: string, eventParams?: IShopInShopEventParams) {
	trackEvent('shopInShop', action, eventParams);
}
