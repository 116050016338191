﻿import {expressEventListener, expressQuerySelector, expressQuerySelectorAll} from "../common/html";
import {getParameter, queryRemove, queryRemoveKeys, updateParameter} from "../utils/querystring";
import { compositeDisposable } from "../utils/disposable";

export interface IShowMoreElementsComponent {
	dispose: () => void;
	resetElement: () => void;
}

export interface ShowMoreClickedReturn {
	allShownDesktop: boolean;
	allShownMobile: boolean;

}

export function initPagedShowMoreElements(containerEl: HTMLElement, buttonEl: HTMLElement, parameter?: string, elementSelector?: string, arrayCounter = -1, showmoreClick?: (currentPage: number, sizeDesktop: number, sizeMobile: number) => Promise<ShowMoreClickedReturn>, afterShowMore?: () => void): IShowMoreElementsComponent {
	const elementElsDesktop = expressQuerySelectorAll(containerEl, (elementSelector ? elementSelector : '.technical-showmore') + '.u-hide-on-desktop-full');
	const elementElsMobile = expressQuerySelectorAll(containerEl, (elementSelector ? elementSelector : '.technical-showmore') + '.u-hide-on-mobile-full');
	const batchSizeDesktop = parseInt(buttonEl.dataset['batchsizeDesktop'] || '999', 10);
	const batchSizeMobile = parseInt(buttonEl.dataset['batchsizeMobile'] || '999', 10);
	const showAllOnMore = buttonEl.dataset['showallonmore'] || false;
	const urlParam = parseInt(getParameter(parameter || "currentpage"));
	let currentPage = isNaN(urlParam) ? parseInt(buttonEl.dataset.currentpage || '0', 0) : urlParam;

	if (elementElsDesktop.length === 0 && elementElsMobile.length === 0 && !showmoreClick)
		return;

	const toggleButtonVisibility = (allDesktopVisible: boolean, allMobileVisible: boolean) => {
		if (allDesktopVisible || showAllOnMore) {
			if (buttonEl.parentElement && !buttonEl.classList.contains('technical-no-hide-parrent'))
				buttonEl.parentElement.classList.add('u-hide-on-desktop-full');
			else
				buttonEl.classList.add('u-hide-on-desktop-full');
		}

		if (allMobileVisible || showAllOnMore) {
			if (buttonEl.parentElement && !buttonEl.classList.contains('technical-no-hide-parrent'))
				buttonEl.parentElement.classList.add('u-hide-on-mobile-full');
			else
				buttonEl.classList.add('u-hide-on-mobile-full');
		}
	};

	const showElements = (sizeMobile: number, sizeDesktop: number) => {
		if (showmoreClick)
			return showmoreClick(currentPage, sizeDesktop, sizeMobile)
				.then(x => {
					toggleButtonVisibility(x.allShownDesktop, x.allShownMobile);
					afterShowMore && afterShowMore();
				});

		const toShowDesktop = showAllOnMore ? elementElsDesktop : elementElsDesktop.splice(0, sizeDesktop);
		toShowDesktop.forEach(e => e.classList.remove('u-hide-on-desktop-full'));

		const toShowMobile = showAllOnMore ? elementElsMobile.splice(0, elementElsMobile.length) : elementElsMobile.splice(0, sizeMobile);
		toShowMobile.forEach(e => e.classList.remove('u-hide-on-mobile-full'));

		toggleButtonVisibility(elementElsDesktop.length === 0, elementElsMobile.length === 0);
		afterShowMore && afterShowMore();
	};

	const showMore = () => {
		currentPage = currentPage + 1;
		buttonEl.dataset.currentpage = currentPage.toString();
		if (parameter) {
			let url = location.href;
			let urlParameter = parameter ? parameter : "currentpage";
			if (arrayCounter >= 0) urlParameter += "[" + arrayCounter + "]";
			url = updateParameter(urlParameter.toLowerCase(), buttonEl.dataset.currentpage, url.toLowerCase());
			history.replaceState(null, '', url.toLowerCase());
		}

		showElements(batchSizeMobile, batchSizeDesktop);
	};

	const disposableEvents = compositeDisposable(
		[
			expressEventListener(buttonEl, 'click', (e: Event) => {
				e.preventDefault();
				showMore();
			})
		]
	);

	// init
	// currentPage on button different from url? => html from cache server side => init showmore here
	const toCheckParameter = (arrayCounter > -1 && parameter ? `${parameter}[${arrayCounter}]` : parameter);
	if (currentPage > 0 && parseInt(getParameter(toCheckParameter || "currentpage")) != parseInt(buttonEl.dataset.currentpage || '0'))
		for (let i = 0; i < currentPage; i++) {
			showElements(batchSizeMobile, batchSizeDesktop);
		}

	return {
		dispose: () => disposableEvents.dispose(),
		resetElement: () => showMore()
	};
}

export function initShowMoreElements(containerEl: HTMLElement, buttonEl: HTMLElement, parameter?: string, elementSelector?: string, arrayCounter = -1, showMoreClick?: (currentPage: number) => Promise<ShowMoreClickedReturn>, afterShowMore?: () => void): IShowMoreElementsComponent {
	let elementElsDesktop = expressQuerySelectorAll(containerEl, (elementSelector ? elementSelector : '.technical-showmore'));
	let elementElsMobile = expressQuerySelectorAll(containerEl, (elementSelector ? elementSelector : '.technical-showmore'));
	const urlParam = parseInt(getParameter(parameter));
	let currentPage = isNaN(urlParam) ? parseInt(buttonEl.dataset.currentpage || '0', 0) : urlParam;
	const buttonTextEl = expressQuerySelector<HTMLElement>(buttonEl, '.technical-link-text', false);
	const buttonIconEl = expressQuerySelector<HTMLElement>(buttonEl, '.a-link__icon', false);

	if (elementElsDesktop.length === 0 && elementElsMobile.length === 0 && !showMoreClick)
		return;

	const reFetchElements = () => {
		elementElsDesktop = expressQuerySelectorAll(containerEl, (elementSelector ? elementSelector : '.technical-showmore'));
		elementElsMobile = expressQuerySelectorAll(containerEl, (elementSelector ? elementSelector : '.technical-showmore'));
	};

	const showElements = () => {
		if (showMoreClick)
			return showMoreClick(currentPage)
				.then(x => {
					afterShowMore && afterShowMore();
				});

		reFetchElements();
		elementElsDesktop.forEach(e => e.classList.remove('u-hide-on-desktop-full'));
		elementElsMobile.forEach(e => e.classList.remove('u-hide-on-mobile-full'));

		afterShowMore && afterShowMore();
	};

	const hideElements = () => {
		reFetchElements();
		elementElsDesktop.slice(parseInt(buttonEl.dataset.baseamount || '0', 10), elementElsDesktop.length).forEach(e => e.classList.add('u-hide-on-desktop-full'));
		elementElsMobile.slice(parseInt(buttonEl.dataset.baseamount || '0', 10), elementElsDesktop.length).forEach(e => e.classList.add('u-hide-on-mobile-full'));

		afterShowMore && afterShowMore();
	};

	const updateUrl = (add: boolean) => {
		if (!add && currentPage !== 0)
			currentPage-=1;
		else if (add && currentPage !== 1)
			currentPage+=1;
		buttonEl.dataset.currentpage = currentPage.toString();
		if (parameter) {
			let url = location.href;
			let urlParameter = parameter ? parameter : "currentpage";
			if (arrayCounter >= 0) urlParameter += "[" + arrayCounter + "]";
			url = updateParameter(urlParameter.toLowerCase(), buttonEl.dataset.currentpage, url.toLowerCase());
			history.replaceState(null, '', url.toLowerCase());
		}
	};

	const showMore = (shouldUpdateUrl = false) => {
		shouldUpdateUrl && updateUrl(true);
		showElements();
	};

	const showLess = (shouldUpdateUrl = false) => {
		shouldUpdateUrl && updateUrl(false);
		hideElements();
	};

	const setShowLess = (shouldUpdateUrl = false) => {
		buttonTextEl.innerHTML = buttonEl.dataset.lesstext;
		buttonIconEl.classList.add('icon-arrow-up');
		buttonIconEl.classList.remove('icon-arrow-down');
		showMore(shouldUpdateUrl);
	};

	const setShowMore = (shouldUpdateUrl = false) => {
		buttonTextEl.innerHTML = buttonEl.dataset.moretext;
		buttonIconEl.classList.remove('icon-arrow-up');
		buttonIconEl.classList.add('icon-arrow-down');
		showLess(shouldUpdateUrl);
	};

	const toggle = () => {
		if (buttonTextEl && buttonIconEl) {
			if (buttonTextEl.innerHTML === buttonEl.dataset.moretext) {
				setShowLess(true);
			} else {
				setShowMore(true);
			}
		} else {
			showMore(true);
		}
	};

	const disposableEvents = compositeDisposable(
		[
			expressEventListener(buttonEl, 'click', (e: Event) => {
				e.preventDefault();
				toggle();
			})
		]
	);

	const cleanUrl = () => {
		if (parameter) queryRemoveKeys([parameter]);
	};

	// init
	// currentPage on button different from url? => html from cache server side => init show more here
	// only for POP, DSP isn't cached and set server side
	const toCheckParameter = (arrayCounter > -1 && parameter ? `${parameter}[${arrayCounter}]` : parameter);
	if (buttonTextEl && buttonIconEl && parseInt(getParameter(toCheckParameter) || '0') != parseInt(buttonEl.dataset.currentpage || '0'))
		if (currentPage === 1) {
			setShowLess();
		} else {
			setShowMore();
		}

	return {
		dispose: () => disposableEvents.dispose(),
		resetElement: () => {
			setShowMore(false);
			cleanUrl();
		}
	};
}
