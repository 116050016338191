﻿import { createCollapsable } from "../components/collapsable";
import { initCountDown } from "./countdown";
import { expressQuerySelectorAll } from "../common/html";

export function createPromotionBar(containerEl: HTMLElement) {

	createCollapsable(containerEl, {
		titleElSelector: '.technical-promotion-bar-header',
		scrollIntoViewAfterOpen: false
	});

	const countDownEls = expressQuerySelectorAll<HTMLElement>(containerEl, ".technical-countdown");
	countDownEls.forEach(el => {
		const countDownToDate = el.dataset.countdown || "";
		const daysLabel = el.dataset.dayslabel || "";
		const dayLabel = el.dataset.daylabel || "";

		if (countDownToDate !== "") {
			const onCountDownFinish = () => {
				const parent = containerEl.parentElement;
				if (parent)
					parent.removeChild(containerEl);
			};

			initCountDown(containerEl, el, countDownToDate, {
				showDays: true,
				onCountDownFinish: onCountDownFinish,
				daysLabel,
				dayLabel
			});
		}
	});
}
