﻿import {
	expressQuerySelectorAll
} from "../common/html";
import { createCollapsable, ICreateCollapsableDependencies } from "../components/collapsable";

export function createFaq(el: HTMLElement) {
	const init = () => {
		const collapsableDeps = {
			contentSelector: '.m-collapsable__content',
			titleElSelector: '.m-collapsable__title',
			scrollIntoViewAfterOpen: false
		} as ICreateCollapsableDependencies;

		const collapsableEls = expressQuerySelectorAll<HTMLElement>(el, '.m-collapsable');
		if (collapsableEls && collapsableEls.length > 0)
			collapsableEls.forEach(el => createCollapsable(el, collapsableDeps));
	};

	init();
}

