﻿export function checkPersonalNumber(pin, regionCode): boolean {
	if (pin.trim() === "")
		return true;

	switch (regionCode) {
		case "SWE":
			return checksumSe(pin);
		case "NOR":
			return checksumNo(pin);
		case "DNK":
			return checksumDk(pin);
		case "FIN":
			return checksumFi(pin);
		default:
			return true;
	}
}

const checksumDk = (pin: string): boolean => {
	if (pin && pin.length === 11) {
		const checkSumString = "4327654321";
		let result1 = 0;
		const temp = pin.toString().substring(0, 6) + pin.toString().substring(7, 10);

		for (let i = 0; i < 9; i++) {
			result1 += parseInt(checkSumString[i].toString(), 10) * parseInt(temp[i].toString(), 10);
		}

		let checksum = 11 - result1 % 11;
		if (checksum === 11)
			checksum = 0;

		if (checksum.toString() === pin[10])
			return true;
		else
			return false;
	} else {
		return false;
	}
};

const checksumFi = (pin: string): boolean => {
	if (pin && pin.length === 11) {
		const checkSumString = "0123456789ABCDEFHJKLMNPRSTUVWXY";

		const temp = parseInt(pin.toString().substring(0, 6) + pin.toString().substring(7, 10), 10);
		const result1 = temp % 31;
		const checksum = checkSumString[result1];

		if (checksum === pin[10])
			return true;
		else
			return false;
	} else {
		return false;
	}
};

const checksumNo = (pin: string): boolean => {
	if (pin && pin.length === 12) {
		let result1 = 0;
		let result2 = 0;
		const checkSumString1 = "376189-452";
		const checkSumString2 = "543276-5432";

		for (let i = 0; i < 10; i++) {
			if (i === 6)
				continue;
			result1 += parseInt(checkSumString1[i].toString(), 10) * parseInt(pin[i].toString(), 10);
		}

		let checksum1 = 11 - result1 % 11;
		if (checksum1 === 11)
			checksum1 = 0;

		if (checksum1.toString() !== pin[10])
			return false;


		for (let i = 0; i < 11; i++) {
			if (i === 6)
				continue;
			result2 += parseInt(checkSumString2[i].toString(), 10) * parseInt(pin[i].toString(), 10);
		}

		let checksum2 = 11 - result2 % 11;
		if (checksum2 === 11)
			checksum2 = 0;

		if (checksum2.toString() !== pin[11])
			return false;
		else
			return true;
	} else {
		return false;
	}
};

const checksumSe = (pin: string): boolean => {
	if (pin && (pin.length === 11 || pin.length === 13)) {
		let result1 = 0;
		const multiplier = "212121-212".split('').map(s => +s); // convert string to number using +
		let temp = 0;

		// format must be 123456-1234
		if (pin.length === 13)
			pin = pin.toString().substring(2);
		const pins = pin.split('').map(s => +s);
		for (let i = 0; i < 10; i++) {
			if (i === 6)
				continue;

			temp = multiplier[i] * pins[i];
			if (temp > 9)
				result1 += parseInt(temp.toString().split('')[0], 10) + parseInt(temp.toString().split('')[1], 10);
			else
				result1 += temp;
		}

		const lastDigit = parseInt(result1.toString().split('')[result1.toString().length - 1], 10);

		let checksum;
		if (lastDigit === 0)
			checksum = 0;
		else
			checksum = 10 - lastDigit;

		return checksum === pins[10];
	} else {
		return false;
	}
};

