﻿import {expressEventListener, expressRemoveClass, expressAddClass} from '../common/html';
import {IDisposable} from "../utils/disposable";

export interface ICreateClickableOptions {
	readonly enable?: () => void;
	readonly disable?: () => void;
}

export interface IButton {
	readonly dispose: () => void;
	readonly enable: () => void;
	readonly disable: () => void;
}

export function createClickableElement(el: HTMLElement, onClick?: () => Promise<void>, options: ICreateClickableOptions = {}) {
	let clickEvent: IDisposable;
	const {enable, disable} = options;

	const onClicked = (e: Event) => {
		e.preventDefault();
		e.stopPropagation();
		if (onClick) {
			dispose();
			disable && disable();
			onClick()
				.then(() => {
					enable && enable();
					addClickEvent();
				});
		}
	};

	const addClickEvent = () => {
		clickEvent = expressEventListener(el, 'click', onClicked);
	};

	const dispose = () => clickEvent.dispose();

	addClickEvent();

	return {
		addClickEvent,
		dispose
	};
}

export function createButton(buttonEl: HTMLButtonElement, onClick?: () => Promise<void>): IButton {
	const enable = () => { // add component specific classes
		expressRemoveClass(buttonEl, "a-button--disabled");
		buttonEl.removeAttribute("disabled");
	};

	const disable = () => {
		expressAddClass(buttonEl, "a-button--disabled");
		buttonEl.setAttribute("disabled", "disabled");
	};

	const button = createClickableElement(
		buttonEl,
		onClick,
		{
			enable,
			disable
		}
	);

	return {
		dispose: button.dispose,
		enable,
		disable
	};
}
