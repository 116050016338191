﻿export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
	let timeoutID = 0;

	const callable = (...args: any[]) => {
		clearTimeout(timeoutID);
		timeoutID = window.setTimeout(() => fn.apply(args), delay);
	};

	return callable;
}

export function throttle<F extends (...params: any[]) => void>(fn: F, delay: number, fnAlways?: F) {
	let lastTimestamp = 0;

	const callable = (...args: any[]) => {
		const now = Date.now();

		fnAlways && fnAlways(...args);
		if (!lastTimestamp || now - lastTimestamp >= delay) {
			lastTimestamp = now;
			fn(...args);
		}
	};

	return callable;
}
