import { expressEventListener, expressQuerySelector } from "../common/html";

export function initVideoPlayer(videoContainerEl: HTMLElement, autoplayOnClick = true) {
	const playerEl = expressQuerySelector<HTMLIFrameElement>(videoContainerEl, '.technical-video-frame', true);
	const overlayEl = expressQuerySelector<HTMLIFrameElement>(videoContainerEl, '.technical-video-overlay', false);
	let overlayButtonEl: HTMLElement;
	if (overlayEl) {
		overlayButtonEl = expressQuerySelector<HTMLIFrameElement>(overlayEl, '.q-video-cover-overlay__button', false);
	}
	let vimeoVideoPlayer;
	let scriptAlreadyLoaded = false;

	const onOverlayClick = (ev: Event) => {
		ev.preventDefault();
		ev.stopPropagation();
		playPauseYoutubeVideo('play');
	};

	const playPauseYoutubeVideo = (action: "play" | "pause") => {
		toggleOverLay(action == 'play');

		const isVimeoVideo = playerEl.src.toLowerCase().indexOf('vimeo') > -1;

		if (isVimeoVideo && !scriptAlreadyLoaded) {
			const scriptEl = document.createElement("script");
			scriptEl.onload = () => {
				vimeoVideoPlayer = new Vimeo.Player(playerEl, {
					autoplay: 1,
					muted: false,
				});
				vimeoVideoPlayer[action]();
				return;
			};
			scriptEl.src = 'https://player.vimeo.com/api/player.js';
			document.body.appendChild(scriptEl);
			scriptAlreadyLoaded = true;
			return;
		} else if (isVimeoVideo && vimeoVideoPlayer && vimeoVideoPlayer[action]) {
			vimeoVideoPlayer[action]();
			return;
		}

		postMessageToPlayer(
			{
				"event": "command",
				"func": action === 'play' ? 'playVideo' : 'pauseVideo',
			}
		);
	};

	const toggleOverLay = (hideOverlay?: boolean) => {
		if (!overlayEl) return;
		overlayEl.classList.toggle('u-hide', hideOverlay);
	};

	const postMessageToPlayer = (command) => {
		if (playerEl && playerEl.contentWindow) {
			playerEl.contentWindow.postMessage(JSON.stringify(command), "*");
		}
	};

	const init = () => {
		if (overlayEl && autoplayOnClick) expressEventListener(overlayEl, 'click', onOverlayClick);
		if (overlayButtonEl && autoplayOnClick) expressEventListener(overlayButtonEl, 'click', onOverlayClick);
	};

	init();

	return {
		dispose: () => { if (overlayEl) expressEventListener(overlayEl, 'click', onOverlayClick); },
		playPauseYoutubeVideo: (action: "play" | "pause") => playPauseYoutubeVideo(action),
		showOverlay: () => toggleOverLay(false),
		isOverlayVisible: () => {
			return overlayEl && !overlayEl.classList.contains("u-hide");
		}
	};
}
