﻿export interface IDisposable {
	dispose: () => void;
	add?: (addDisposable: IDisposable) => void;
	addMany?: (addDisposables: IDisposable[]) => void;
}

/** Returns a composite disposable (disposing all child disposables) */
export function compositeDisposable(disposables: IDisposable[] | undefined): IDisposable {
	// TODO: This creates a closure, optimize?
	const dispose = (): void => {
		if (composite) {
			// To avoid endless loops when calling dispose on the composite again, we first clear it.
			composite = null;
			if (disposables) disposables.forEach(d => d.dispose());
		}
	};
	const addMany = (addDisposables: IDisposable[]): void => {
		if (!disposables)
			disposables = [];
		disposables.push(...addDisposables);
	};
	const add = (addDisposable: IDisposable): void => {
		if (!disposables)
			disposables = [];
		disposables.push(addDisposable);
	};
	let composite: IDisposable | null = { dispose, add, addMany };
	return composite;
}
