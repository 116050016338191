﻿// SG organism: http://brandplatform.smartphoto.com/general/organisms/highlighted-tiles/
import { expressEventListener, expressQuerySelectorAll } from "../common/html";
import { IDisposable } from "../utils/disposable";
import { debounce } from "../utils/debounce";
import { createTruncate, ITruncate } from "./truncate";
import {initVideoPlayer} from "./video-player";

export enum Device {
	Desktop = 1,
	Tablet = 2,
	Mobile = 3
}

export function createHighlightedTiles(containerEl: HTMLElement) {
	const truncateEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-shave');
	const videoEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-video-tile');

	let eventDisposable: IDisposable;
	let currentDevice: Device;

	const getDevice = (): Device => {
		if (window.innerWidth <= 767) {
			return Device.Mobile;
		} else if (window.innerWidth > 768 && window.innerWidth < 960) {
			return Device.Tablet;
		} else {
			return Device.Desktop;
		}
	};

	const onResize = () =>
		currentDevice !== getDevice() &&
		truncatedEls.forEach(t => t.updateConfig({ lines: 2 }));

	let truncatedEls: ITruncate[] = [];

	const init = () => {
		truncatedEls = truncateEls.map(el => createTruncate(el, { lines: 2 }));
		eventDisposable = expressEventListener(window, "resize", debounce(onResize, 250));
		videoEls && videoEls.forEach(vEl => initVideoPlayer(vEl));
	};

	init();

	return {
		dispose: () => {
			eventDisposable.dispose();
			truncatedEls.forEach(t => t.dispose());
		}
	};
}
