﻿import {compositeDisposable, IDisposable} from "../utils/disposable";
import {expressEventListener} from "../common/html";

export function initLanguageSelector(buttonEl: HTMLElement, containerEl: HTMLElement) {
	let eventDisposables: IDisposable;
	let onDocumentClickDisposable: IDisposable;

	const closeMenu = () => {
		containerEl.classList.add("u-hide");
		detachDocumentClick();
	};

	const onDocumentClick = (e: MouseEvent) => {
		if (!(e.target as HTMLElement).closest(".technical-language-selector, .technical-language-selector-choices")) {
			closeMenu();
		}
	};

	const onButtonClick = () => {
		// we do don't want to load or attach handlers when the menu is closing
		if (containerEl.classList.contains("u-hide"))
			attachOnDocumentClick();
		containerEl.classList.toggle("u-hide");
	};

	const attachOnDocumentClick = () =>
		(
			onDocumentClickDisposable = compositeDisposable (
				[expressEventListener(document, 'click', onDocumentClick, true)]
			)
		);

	const detachDocumentClick = () => onDocumentClickDisposable && onDocumentClickDisposable.dispose();

	const init = () => {
		eventDisposables = compositeDisposable (
			[
				expressEventListener(buttonEl, 'click', onButtonClick)
			]
		);
	};

	const deInit = () => {
		dispose();
		closeMenu();
	};

	// remove all handlers
	const dispose = () => {
		if (eventDisposables) eventDisposables.dispose();
		detachDocumentClick();
	};

	// init component
	init();

	return {
		deInit
	};
}
