﻿export interface IIconResult {
	readonly url: string;
	readonly svg: string;
}

const getInlineSvgAsync = (url: string): Promise<IIconResult | undefined> => {
	return fetch(url)
		.then(response => response.text())
		.then(data => new window.DOMParser().parseFromString(data, "text/xml"))
		.then(document => {
			if (document && document.documentElement.nodeName.toLowerCase() === 'svg') {
				const svgString = new XMLSerializer().serializeToString(document);
				return Promise.resolve(
					{
						url,
						svg: svgString
					}
				);
			} else {
				return Promise.resolve(undefined);
			}
		});
};

export function getInlineSvgsAsync(urls: string[]) {
	return Promise.all(urls.map(u => getInlineSvgAsync(u)))
		.then(items => items.filter(item => !!item) as IIconResult[]);
}