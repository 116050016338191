﻿import {IDisposable} from "../utils/disposable";
import {expressEventListener, expressQuerySelector} from "../common/html";

export interface ICountrySelectionDependencies {
    readonly showCountrySelectionPopup: () => Promise<void>;
    readonly close: () => void;
}

export function createCountrySelection(deps: ICountrySelectionDependencies) {
    let onClickDisposable: IDisposable;

    const init = () => {
        deps.showCountrySelectionPopup().then(() => {
            const countrySelectionPopup = expressQuerySelector<HTMLElement>(document, '#country-selection');
            if (countrySelectionPopup) {
                const continueButton = expressQuerySelector<HTMLElement>(countrySelectionPopup, '.technical-countryselection');
                if (continueButton) {
                    if (continueButton.dataset.countryselectionlink) {
                        const navigateToLink = continueButton.dataset.countryselectionlink.toLowerCase();
                        attachOnClick(continueButton, () => navigateTo(navigateToLink));
                    }
                }
            }
        });
    };

    const navigateTo = (navigateToLink: string | undefined) => {
        if (navigateToLink) document.location.href = navigateToLink;

        deps.close();
    };

    const attachOnClick = (button: HTMLElement, ev: any) =>
        (onClickDisposable = expressEventListener(button, 'click', ev));

    const detachClick = () => onClickDisposable && onClickDisposable.dispose();

    init();

    return {
        dispose: () => {
            detachClick();
        }
    };
}
