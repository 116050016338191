import {
	expressQuerySelectorAll,
	expressRemoveClass,
	hasClass,
	isElementPartiallyVisibleInViewport,
	isMobileOnPageWithHeader
} from "../common/html";
import {getComponentContent} from "../services/component-service.";
import {trackComponentEvent} from "../common/events";
import {pushComponentEvent} from "./datalayer";
import {isValidPartialViewResponse} from "../utils/fetch";

export interface IComponent {
	readonly name: string;
	readonly activate: () => void;
	readonly index: string;
}

export function createComponentLoader() {
	const componentEls = expressQuerySelectorAll<HTMLElement>(document, "[data-component]");
	const components: IComponent[] = [];
	/*const loadedScripts: string[] = [];
	const scriptBasePath = "core/components/";*/
	const isMobile = () => isMobileOnPageWithHeader();

	const observerOptions = {
		rootMargin: "200px 0px 200px 0px",
		threshold: [0, .5, 1]
	};

	const initComponent = (el: HTMLElement) => {
		const componentName =el.dataset.component || '';
		const index = el.dataset.index || '';
		const initialized = el.dataset.init === 'true' || false;
		if (!componentName || initialized) return;
		const currentComponent = index
			? components.find(c => c.name === componentName && c.index === index)
			: components.find(c => c.name === componentName);
		if (!currentComponent) return;
		currentComponent.activate();
	};

	function callback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		entries.forEach(entry => {
			if (!entry.isIntersecting && entry.intersectionRatio <= 0) return;
			initComponent(entry.target as HTMLElement);
			observer.unobserve(entry.target);
		});
	}

	const componentObserver = new IntersectionObserver(callback, observerOptions);

	const createComponent = (componentEl: HTMLElement) => {
		const name = componentEl.dataset.component || '';
		if (!name) return;
		const callback = componentEl.dataset.componentCallback || '';
		const passElInCallback = componentEl.dataset.componentPassElementInCallback === "true" || false;
		const trackGaEvent = componentEl.dataset.componentGaEvent || '';
		const trackHeight = componentEl.dataset.componentTrackHeight === "true" || false;
		const pushDatalayerEvent = componentEl.dataset.componentPushDatalayerEvent === "true" || false;
		const contentKey = componentEl.dataset.componentContentkey || '';
		const epc = componentEl.dataset.componentEpc|| '';
		const isProspect = componentEl.dataset.componentIsProspect === "true" || false;
		const themeKey = componentEl.dataset.componentTheme || '';
		const pageTemplate = componentEl.dataset.componentTemplate || '';
		const replaceNode = componentEl.dataset.replaceNode || false;
		const pageName = componentEl.dataset.componentPageName || '';

		// Set an index in order for the observer to target the correct element
		let index = "0";
		if (components.find(el => el.name === name)) {
			index = components.filter(el => el.name === name).length+"";
		}
		componentEl.dataset.index = index;

		if (trackHeight) {
			const height = sessionStorage.getItem(name + (isMobile ? "Mobile" : "Desktop") + "Height");
			if (height) {
				componentEl.style.minHeight = height + 'px';
			}
		}

		const addComponentContent = (): Promise<boolean> => {
			return getComponentContent(name, contentKey, epc, isProspect, themeKey, pageTemplate, pageName, index).then(r => {
				if (!r || !isValidPartialViewResponse(r)) return false;
				const parent = componentEl.parentElement;
				if (parent && replaceNode) {
					const elementHtml = document.createRange().createContextualFragment(r);
					parent.replaceChild(elementHtml, componentEl);
					componentEl = parent;

					if (trackGaEvent) trackComponentEvent(trackGaEvent);
					if (trackHeight) {
						const height = componentEl.offsetHeight;
						sessionStorage.setItem(name + (isMobile ? "Mobile" : "Desktop") + "Height", height + '');
					}

				} else {
					componentEl.insertAdjacentHTML("afterbegin", r);

					if (trackGaEvent) trackComponentEvent(trackGaEvent);
					if (trackHeight) {
						const height = componentEl.offsetHeight;
						sessionStorage.setItem(name + (isMobile ? "Mobile" : "Desktop") + "Height", height + '');
					}

					if (hasClass(componentEl, 'skeleton-loading')) {
						expressRemoveClass(componentEl, 'skeleton-loading');
					}
					if (componentEl.getAttribute("style")) {
						componentEl.removeAttribute("style");
					}
				}

				if (pushDatalayerEvent) {
					pushComponentEvent(name);
				}
				return true;
			});
		};

		const loadJavascriptAndRunMethod = () => {
			if (callback) {
				/* For now we disable the load of js on demand
				if (!loadedScripts.includes(name)) {
					const scriptEl = document.createElement("script");
	
					scriptEl.addEventListener("load", () => {
						loadedScripts.push(name);
						if (passElInCallback) {
							window[callback].call(this, componentEl);
						} else {
							window[callback].call(this);
						}
					});
	
					scriptEl.src = getCdnPathForJsFile(scriptBasePath + name + ".min.js");
					document.body.appendChild(scriptEl);
				} else { */
					if (passElInCallback) {
						window[callback].call(this, componentEl);
					} else {
						window[callback].call(this);
					}
				/*} */
			}
		};

		const activate = () => {
			componentEl.dataset.init = 'true';
			addComponentContent().then(r => {
				if (r) {
					if (callback) loadJavascriptAndRunMethod();
				} else {
					componentEl.dataset.error = 'true';
				}
			});
		};

		const component = {
			name,
			activate,
			index
		} as IComponent;
		components.push(component);
	};

	const init = (): Promise<void> => {
		componentEls.forEach(el => createComponent(el));
		return Promise.resolve();
	};

	init().then(_ => {
		componentEls.forEach(el => {
			if (isElementPartiallyVisibleInViewport(el, 10)) {
				initComponent(el);
			} else {
				componentObserver.observe(el);
			}
		});
	});
}
