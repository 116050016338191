import {expressEventListener, expressQuerySelector} from "../common/html";

export function createAbandonedVisitTitleSwapper() {
	const origTitle = document.title;
	const titleEl = expressQuerySelector(document, 'title', true);
	if (titleEl.dataset.abandonedvisitenabled && titleEl.dataset.abandonedvisitenabled.toLowerCase() === "false") return;
	let blinkInterval: number | null;
	const blinkTitle = () => {
		const currentTitle = document.title;
		if (currentTitle === titleEl.dataset.abandonedvisittitle)
			document.title = titleEl.dataset.abandonedvisittitlealternate;
		else
			document.title = titleEl.dataset.abandonedvisittitle;
	};

	let hidden, visibilityState, visibilityChange;

	if (typeof document.hidden !== "undefined") {
		hidden = "hidden"; visibilityChange = "visibilitychange"; visibilityState = "visibilityState";
	}
	// @ts-ignore
	else if (typeof document.mozHidden !== "undefined") {
		hidden = "mozHidden"; visibilityChange = "mozvisibilitychange";	visibilityState = "mozVisibilityState";
	}
	// @ts-ignore
	else if (typeof document.msHidden !== "undefined") {
		hidden = "msHidden"; visibilityChange = "msvisibilitychange"; visibilityState = "msVisibilityState";
	}
	// @ts-ignore
	else if (typeof document.webkitHidden !== "undefined") {
		hidden = "webkitHidden"; visibilityChange = "webkitvisibilitychange"; visibilityState = "webkitVisibilityState";
	}

	if (typeof document.addEventListener === "undefined" || typeof hidden === "undefined") {
		// not supported
	} else {
		expressEventListener(document, visibilityChange, () => {
			setTimeout(() => {
				switch (document[visibilityState]) {
					case "visible":
						clearInterval(blinkInterval);
						blinkInterval = null;
						document.title = origTitle;
						break;
					case "hidden":
						if (!blinkInterval) {
							const interval = parseInt(titleEl.dataset.abandonedvisitspeed, 10) || 2000;
							// @ts-ignore
							blinkInterval = setInterval(blinkTitle, interval);
						}
						break;
				}
			}, 0);
		}, false);
	}
}
