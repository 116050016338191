﻿import { expressQuerySelector, expressQuerySelectorAll, hasClass } from "../common/html";
import { getParameter } from "../utils/querystring";
import { toUrlHash } from "../utils/string";

export interface ITabDependencies {
	readonly onBeforeOpen?: (tabContentEl: HTMLElement) => Promise<void>;
	readonly onTabSwitch?: (oldTabId: string, newTabId: string) => void;
}

export interface ITabContentRequest {
	id: string;
	productCategory: string;
	theme: string;
}

export function CreateTabs(container: HTMLElement, deps?: ITabDependencies) {
	let tabsCollectionContainer;
	if (hasClass(container, 'm-tabs')) {
		tabsCollectionContainer = container;
	} else {
		tabsCollectionContainer = expressQuerySelector<HTMLElement>(container, '.m-tabs', true);
	}
	const tabHideClass = tabsCollectionContainer.dataset.hideclass || 'u-hide';
	const init = () => {
		const tabEls = expressQuerySelectorAll<HTMLInputElement>(tabsCollectionContainer, '.m-tabs__input');
		if (tabEls.length > 1) {
			const urlParams = new URLSearchParams(window.location.search);
			const activeTab = urlParams.get('activetab');
			if (tabEls.filter(el => el.dataset.url).length === 0) {
				fixDataAttributes(tabEls);
			}
			updateTab(tabEls, tabHideClass, activeTab);
			addEventListenerToTabs(tabEls);
		}
	};
	const addQueryStringParameter = (key: string, value: string) => {
		if ('URLSearchParams' in window) {
			const searchParams = new URLSearchParams(window.location.search);
			searchParams.set(key, value);
			const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
			history.replaceState({}, document.title, newRelativePathQuery);
		}
	};

	const fixDataAttributes = (tabEls: HTMLInputElement[]) => {
		const tabContentEls = expressQuerySelectorAll<HTMLElement>(tabsCollectionContainer, '.m-tabs__content');
		if (tabEls.filter(el => el.dataset.url).length === 0) {
			let hash;
			for (let i = 0; i < tabEls.length; i++) {
				if (tabEls[i].hasAttribute('id')) {
					hash = toUrlHash(tabEls[i].getAttribute('id'));
					tabEls[i].dataset.url = hash;
					tabContentEls[i].dataset.url = hash;
				} else {
					hash = 'tab' + i + '';
					tabEls[i].dataset.url = hash;
					tabContentEls[i].dataset.url = hash;
				}
			}
		}
	};

	const addEventListenerToTabs = (tabEls: HTMLInputElement[]) => {
		// make tabs click-able
		for (let i = 0; i < tabEls.length; i++)
			tabEls[i].addEventListener('change', (e) => {
				updateTab(tabEls, tabHideClass, (e.target as HTMLElement).dataset.url);
			});
	};

	// update tab content visibility
	const updateTab = (tabEls: HTMLInputElement[], hideClass: string, hash: string | undefined, pushNewHash = true) => {
		const tabToSelect = hash ? expressQuerySelector<HTMLInputElement>(tabsCollectionContainer, `input[data-url="${hash}"]`, false) : undefined;
		const tabToSelectContent = hash ? expressQuerySelector<HTMLElement>(tabsCollectionContainer, `div[data-url="${hash}"]`, false) : undefined;
		const tabContentEls = expressQuerySelectorAll<HTMLElement>(tabsCollectionContainer, '.m-tabs__content');

		if (tabToSelectContent && deps?.onBeforeOpen && tabToSelect && (!tabToSelect.dataset.loaded || tabToSelect.dataset.loaded !== 'true')) {
			deps.onBeforeOpen(tabToSelectContent).then(_ => { tabToSelect.dataset.loaded = 'true'; });
		}

		for (let z = 0; z < tabEls.length; z++) {
			// set check if selected tab
			tabEls[z].checked = (tabEls[z] === tabToSelect || (!tabToSelect && z === 0));

			// hide/show content selected tab
			tabContentEls[z].classList.toggle(hideClass || 'u-hide', !tabEls[z].checked);

			// push new # to the url bar
			if (tabEls[z].checked && pushNewHash && tabToSelect) {
				deps?.onTabSwitch && deps.onTabSwitch(getParameter('activetab'), hash);
				addQueryStringParameter("activetab", `${hash}`);
			}
		}
	};

	init();
}
