import {expressEventListener, expressQuerySelector} from "../common/html";
import {ShowContentDialog} from "./content-dialog";
import {CreateTabs} from "./tab";

export function createPopupLauncher(el: HTMLElement) {
	const dialogId = el.dataset.dialogid || '';
	const contentKey = el.dataset.label || '';
	if (dialogId && contentKey) {
		const initContentParts = (popupEl: HTMLElement) => {
			if (!popupEl) return;
			const tabsEl = expressQuerySelector<HTMLElement>(popupEl, ".m-tabs", false);
			if (tabsEl) CreateTabs(tabsEl);
		};
		const onClick = (e:MouseEvent) => {
			e.preventDefault();
			ShowContentDialog({dialogId: dialogId, label: contentKey, openFunction: initContentParts});
		}; 
		expressEventListener(el, 'click', onClick);
	}
}
