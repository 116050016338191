﻿import {IErrorLog, postRequest} from "../utils/fetch";
import {escapeForJavascript} from "../utils/string";

export function logErrorEvent(event: Event): boolean {
	const errorEv = event as ErrorEvent;
	return logError(errorEv.message, errorEv.filename, errorEv.lineno, errorEv.colno, errorEv.error);
}

export function logError(msg: string, url?: string, line?: number, col?: number, error?: Error, process?: string): boolean {
	const lastLoggedError = ["", "", "", 0];
	let totalLoggedErrors = 0;

	try {
		// Empty URLs, this is a browser issue or cross domain error (external JS files)
		if (!url && !line) return;

		/* For not log all errors in .net core except from line above
		if (msg && msg === "Error loading script" && line === 1) {
			return;
		}
		if (msg && msg === "Script error." && line === 0) {
			return;
		}
		if (msg && msg === "Script error" && line === 0) {
			return;
		}

		// Other specific errors
		if (msg && msg.toString().indexOf("EBCallBackMessageReceived") >= 0) {
			return;
		}
		if (msg && msg.toString().indexOf("NPObject") >= 0) {
			return;
		}
		if (msg && msg.toString().indexOf("cX") >= 0 && line === 1) {
			return;
		}
		if (msg && msg.toString().indexOf("google_trackConversion") >= 0 && line === 1) {
			return;
		}
		if (msg && msg.toString().indexOf("ma_track") >= 0 && line === 1) {
			return;
		} */

		let urlString = "";
		let errorString = "";

		// Simple URLs that give errors out of our control, Ignore keys
		if (Array.isArray(window.errorLogStrings) && Array.isArray(window.errorLogStrings['Ignore']) && !(!window.errorLogStrings['Ignore'].length)) {
			for (let i = 0; i < window.errorLogStrings['Ignore'].length; i++) {
				urlString = window.errorLogStrings['Ignore'][i][1];
				errorString = window.errorLogStrings['Ignore'][i][2];

				// check if both errorLogString values are filled
				if (url && msg && urlString !== "" && errorString !== "") {
					if (url.indexOf(urlString) >= 0 && msg.toString().indexOf(errorString) >= 0) {
						return;
					}
				}
				// check if url contains urlString or if msg contains errorString
				if (url && urlString !== "") {
					if (url.indexOf(urlString) >= 0) {
						return;
					}
				}
				if (msg && errorString !== "") {
					if (msg.toString().indexOf(errorString) >= 0) {
						return;
					}
				}
			}
		}

		if (lastLoggedError[0] === msg && lastLoggedError[1] === url && lastLoggedError[2] === line) {
			let val = +lastLoggedError[3];
			lastLoggedError[3] = val++;
		} else {
			lastLoggedError[0] = msg.toString();
			lastLoggedError[1] = url;
			lastLoggedError[2] = line;
			lastLoggedError[3] = 1;
		}

		if (totalLoggedErrors < 10 && lastLoggedError[3] <= 3) {
			totalLoggedErrors++;
			process = process || 'Browser';
			for (const key in window.errorLogStrings) {
				// Only handle keys !== Ignore
				if (key !== "Ignore") {
					for (let i = 0; i < window.errorLogStrings[key].length; i++) {

						urlString = window.errorLogStrings[key][i][1];
						errorString = window.errorLogStrings[key][i][2];

						// check if both errorLogString values are filled
						if (url && msg && urlString !== "" && errorString !== "") {
							if (url.indexOf(urlString) >= 0 && msg.toString().indexOf(errorString) >= 0) {
								process = key;
							}
						} else {
							// check if url contains urlString or if msg contains errorString
							if (url && urlString !== "") {
								if (url.indexOf(urlString) >= 0) {
									process = key;
								}
							}
							if (msg !== null && errorString !== "") {
								if (msg.toString().indexOf(errorString) >= 0) {
									process = key;
								}
							}
						}
					}
				}
			}

			if (error && error.stack)
				msg += "\n\nStacktrace: \n" + error.stack;

			const errorLog: IErrorLog = {
				line: line,
				message: escapeForJavascript(msg),
				process: process,
				url: url
			};

			postRequest<IErrorLog, string>("/api/v1/ApplicationService.svc/json/LogJavascriptError", errorLog, {headers: new Headers({'Content-type': 'application/json; charset=utf-8'})}).then(res => {
				//ts-gitignore
			});
		}
	} catch (exc) {
		//ts-gitignore
	}
	return false;
}
