// This component will be used on the pages typescript files and will execute the scripts tht should fire on every page e.g. footer, header, etc
import { logErrorEvent } from "../services/logger-service";
import { getCookie, writeCookie } from "../utils/cookie";
import {expressQuerySelector, expressQuerySelectorAll} from "../common/html";
import { createCollapsable } from "./collapsable";
import { createNewsletterSubscribeAndUnsubscribe } from "./newsletter";
import { createMainNavigation } from "./main-navigation";
import { getInlineSvgsAsync } from "../utils/icons";
import { createCountrySelection } from "./country-selection";
import { createPopup } from "./popup-new";
import {
	canNotContainOnlyNumerics,
	isValidEmailAddress,
	isValidOrderNumber,
	isValidTextWithoutAngleBrackets,
	isValidWithoutNonAlphaNumericCharsAtEnd
} from "../utils/validation";
import { checkPersonalNumber } from "../utils/personalNumbers";
import { createScrollToTop } from "./scroll-to-top";
import { initLeaveIntent } from "./leave-intent";
import { createPopupLauncher } from "./popup-launcher";
import { createComponentLoader } from "./component-loader";
import { isValidVatNumber } from "../utils/vat";
import { getDate } from "../utils/date";
import {createAbandonedVisitTitleSwapper} from "./abandoned-visit-title-swapper";
import {isMobileDevice, isTouchDevice} from "../common/device";

export interface IGeneralPageComponents {
	readonly disableLeaveIntent: () => void;
	readonly enableLeaveIntent: () => void;
}

export function createGeneralPageEventsAndComponents() {
	// Load GTMIframe
	const gtmIFrame = expressQuerySelector<HTMLIFrameElement>(document, '.technical-loadGTMiframe', false);
	if (gtmIFrame)
		gtmIFrame.src = gtmIFrame.dataset.src;

	const leaveIntent = initLeaveIntent();

	// Load dataLayerIframe
	const dataLayerIframe = document.querySelector<HTMLElement>('#dataLayerIframe');
	if (!dataLayerIframe.getAttribute('data-cookieconsent')) {
		dataLayerIframe.setAttribute('data', dataLayerIframe.getAttribute('data-src'));
	}
	window.addEventListener("CookiebotOnAccept", function () {
		if (window.Cookiebot.consent.marketing) {
			const dataLayerIframe = document.querySelector('#dataLayerIframe');
			dataLayerIframe.setAttribute('data', dataLayerIframe.getAttribute('data-src'));

			if ((typeof window.enableLeaveIntentConfigValue === "undefined" || window.enableLeaveIntentConfigValue)) {
				leaveIntent.EnableLeaveIntent();
				leaveIntent.EnableLeaveIntentPopup();
			}
		}
	});

	// Set global props for the form validation (if we have forms)
	const forms = expressQuerySelectorAll(document, 'form');
	if (Pristine && forms && forms.length > 0) {
		const isOptional = (el: HTMLInputElement) => {
			return !el.hasAttribute('required') && !el.value;
		};

		Pristine.addMessages((window as any).context.language, (window as any).validationMessages);
		Pristine.setLocale((window as any).context.language);
		Pristine.addValidator("min", function (val, limit) {
			return isOptional(this as HTMLInputElement) || (this as HTMLInputElement).type === "date" || parseFloat(val) >= parseFloat(limit);
		}, window.validationMessages['min'], 1, true);
		Pristine.addValidator("ordernumber", function (val) {
			return isValidOrderNumber(val);
		}, window.validationMessages['ordernumber'], 1, true);
		Pristine.addValidator("max", function (val, limit) {
			return isOptional(this as HTMLInputElement) || (this as HTMLInputElement).type === "date" || parseFloat(val) <= parseFloat(limit);
		}, window.validationMessages['max'], 1, true);
		Pristine.addValidator("mindate", function (val, limitValue) {
			const date = new Date(val);
			const limit = getDate(limitValue.replaceAll('/', '-').replaceAll('.', '-'));

			return isOptional(this as HTMLInputElement) || (date && limit && date >= limit);
		}, window.validationMessages['min'], 1, true);
		Pristine.addValidator("maxdate", function (val, limitValue) {
			const date = new Date(val);
			const limit = getDate(limitValue.replaceAll('/', '-').replaceAll('.', '-'));

			return isOptional(this as HTMLInputElement) || (date && limit && date <= limit);
		}, window.validationMessages['max'], 1, true);
		Pristine.addValidator("maxlength", function (val, limit) {
			return isOptional(this as HTMLInputElement) || val && val.length <= limit;
		}, window.validationMessages['maxlength'], 1, true);
		Pristine.addValidator("minlength", function (val, limit) {
			return isOptional(this as HTMLInputElement) || val && val.length >= limit;
		}, window.validationMessages['minlength'], 1, true);
		Pristine.addValidator("vat", function (value, vat) {
			return isOptional(this as HTMLInputElement) || isValidVatNumber(value, vat);
		}, window.validationMessages['vat'], 1, true);
		Pristine.addValidator("regex", function (value, regexp) {
			// get the regex yourself form the form input => the regexp of pristine is limited to 1119 chars
			const regex = (this as HTMLElement).getAttribute('regex') || (this as HTMLElement).getAttribute('data-pristine-regex');
			return isOptional(this as HTMLInputElement) || new RegExp(regex).test(value);
		}, window.validationMessages['regex'], 1, true);
		Pristine.addValidator("phonenumber", function (value, regexp) {
			// get the regex yourself form the form input => the regexp of pristine is limited to 1119 chars
			const regex = (this as HTMLElement).getAttribute('phonenumber') || (this as HTMLElement).getAttribute('data-pristine-phonenumber');
			return isOptional(this as HTMLInputElement) || new RegExp(regex).test(value);
		}, window.validationMessages['phonenumber'], 1, true);
		Pristine.addValidator("urlsafe", function (value) {
			return isOptional(this as HTMLInputElement) || isValidTextWithoutAngleBrackets(value);
		}, window.validationMessages['urlsafe'], 1, true);
		Pristine.addValidator("blacklist", function (value, blacklist) {
			if (!blacklist)
				return true;
			const list = blacklist.split(',');
			for (let i = 0; i < list.length; i++)
				if (value.toLowerCase() === list[i])
					return false;

			return true;
		}, window.validationMessages['blacklist'], 1, true);
		Pristine.addValidator("emailrfc822", function (value) {
			return isValidEmailAddress(value);
		}, null, 98, true); // validation will be fired after required validation a stop if it fails. If we don't do this the normal email validation is also fired and we get two equal error messages
		Pristine.addValidator("personalnumber", function (value) {
			return checkPersonalNumber(value, this.dataset.regioncode || "");
		}, window.validationMessages['personalnumber'], 1, true);
		Pristine.addValidator("usehousenumber", function (value, useHouseNumber) {
			if (!useHouseNumber) return true;
			const prefix = this.dataset.prefix || "";
			const houseNumberEl = expressQuerySelector<HTMLInputElement>(this.form, "input[name='" + prefix + "HouseNumber']", false);
			if (!houseNumberEl || !houseNumberEl.value) return true;
			const atStart = value.trim().substring(0, houseNumberEl.value.trim().length + 1) === houseNumberEl.value.trim() + ' ';
			const atEnd = value.trim().substring(value.trim().length - (houseNumberEl.value.trim().length + 1), value.trim().length) === ' ' + houseNumberEl.value.trim();
			return !atStart && !atEnd;
		}, window.validationMessages['usehousenumber'], 1, true);
		Pristine.addValidator("passwordcheck", function (value) {
			const passwordEl = expressQuerySelector<HTMLInputElement>(this.form, "input[name='password']", false);
			if (!passwordEl) return true;
			return value === passwordEl.value;
		}, window.validationMessages['passwordcheck'], 1, true);
		Pristine.addValidator("strongpassword",
			(value) => {
				const passwordEl = expressQuerySelector<HTMLInputElement>(document, "input[data-pristine-strongpassword='true']", false);
				if (passwordEl.dataset['lastvalidatedpassword'] == value)
					return passwordEl.dataset['lastvalidatedresult'] === "true";
				else
					return true;
			}, window.validationMessages['strongpassword'], 2, true),
		Pristine.addValidator("termsrequired", function (value) {
			const element = expressQuerySelector<HTMLElement>(this.form, '[data-pristine-termsrequired]', false);
			return (element && element instanceof HTMLInputElement && element.checked);
		}, window.validationMessages['termsrequired'], 1, true);
		Pristine.addValidator("invalidchars", function (value) {
			return isOptional(this as HTMLInputElement) || isValidWithoutNonAlphaNumericCharsAtEnd(value, this as HTMLElement);
		}, window.validationMessages['invalidchars'], 1, true);
		Pristine.addValidator("notonlynumbers", function (value) {
			return canNotContainOnlyNumerics(value);
		}, window.validationMessages['notonlynumbers'], 1, true);
	}

	// Add click event to buttons with a data-url attribute
	const buttons = document.querySelectorAll('button[data-url]:not(.technical-disable-dataurl-onclick)');
	buttons.forEach(el => {
		el.addEventListener('click', (ev) => {
			ev.preventDefault();
			window.location.href = el.getAttribute('data-url');
		});
	});

	// Set the isMobileDevice Cookie
	checkMobileAndSetCookie();

	// Initialize the header
	const openLeadTimesPopupAsync = () => {
		const initCollapsingPanels = (popup: HTMLElement) => {
			const collapsibleEls = expressQuerySelectorAll<HTMLElement>(popup, '.m-collapsable');
			collapsibleEls.forEach(el => {
				createCollapsable(el, {
					scrollIntoViewAfterOpen: false,
					titleElSelector: '.m-collapsable__title'
				});
			});
		};
		return createPopup(
			{
				view: "LeadTimesDialog",
				dialogId: "lead-times-dialog",
				forceRefresh: true,
				onBeforeOpen: (popup) => initCollapsingPanels(popup)
			}
		)
			.then(popup => {
				if (!popup.popup) return;
				return popup.openAsync();
			});
	};

	const header = expressQuerySelector<HTMLElement>(document, ".technical-header", false);
	header && createMainNavigation(
		header,
		{
			getInlineSvgsAsync: getInlineSvgsAsync,
			openLeadTimesPopupAsync: openLeadTimesPopupAsync
		}
	);

	window.addEventListener('error', logErrorEvent);

	// Init the country selection popup
	const bodyEl = expressQuerySelector<HTMLElement>(document, "body", true);
	const showCountrySelectionPopup = bodyEl.dataset.showcountryselection === "true" || false;
	const closeCountrySelectionPopup = () => {
		writeCookie("ShowCountrySelectionPopupAgainFrom", new Date().toISOString().slice(0, 10), 365);
	};

	const openCountrySelectionPopup = () => {
		return createPopup(
			{
				view: "CountrySelectionDialog",
				dialogId: "country-selection",
				forceRefresh: true,
				fromCache: false,
				onClose: closeCountrySelectionPopup,
				data: { path: window.location.pathname }
			}
		)
			.then(popup => {
				if (!popup.popup) return;
				return popup.openAsync();
			});
	};

	if (showCountrySelectionPopup) {
		createCountrySelection({
			showCountrySelectionPopup: () => openCountrySelectionPopup(),
			close: () => closeCountrySelectionPopup(),
		});
	}

	// Create scroll to top
	createScrollToTop();

	// create popup launchers (links from cms with a content dialog parser)
	const popupLauncherEls = expressQuerySelectorAll<HTMLElement>(document, '.technical-popup-launcher');
	popupLauncherEls.forEach(el => createPopupLauncher(el));

	// init the component loader if needed
	const htmlElement = expressQuerySelector<HTMLElement>(document, 'html', true);
	const isBot = htmlElement.dataset.isbot.toLowerCase() === 'true' || false;
	if (!isBot) createComponentLoader();

	createNewsletterSubscribeAndUnsubscribe();

	// Create global var for methods of dynamic components
	window['initFooterComponent'] = function initFooterComponent() {
		const footerCollapsibles = expressQuerySelectorAll(document, '.technical-footer-collapsable');
		footerCollapsibles.forEach(el => createCollapsable(<HTMLElement>el, {
			titleElSelector: '.technical-footer-collapsable-title',
			scrollIntoViewAfterOpen: false
		}));

		const footerEl = expressQuerySelector<HTMLElement>(document, '.technical-footer', false);
		if (footerEl) createNewsletterSubscribeAndUnsubscribe(footerEl);
	};

	!isMobileDevice() && createAbandonedVisitTitleSwapper();

	return {
		disableLeaveIntent: leaveIntent.DisableLeaveIntent,
		enableLeaveIntent: leaveIntent.EnableLeaveIntent,
	} as IGeneralPageComponents;
}

const checkMobileAndSetCookie = () => {
	if (isTouchDevice() && (window.innerWidth < 500 || window.innerHeight < 500)) {
		if (getCookie("IsMobileDevice") !== "true")
			writeCookie("IsMobileDevice", "true", 365);
	} else {
		if (getCookie("IsMobileDevice") !== "false")
			writeCookie("IsMobileDevice", "false", 365);
	}
};
