import {
	expressAddClass,
	expressEventListener,
	expressQuerySelector,
	getParents, hide,
	htmlToElement,
	expressQuerySelectorAll, expressRemoveClass, show
} from "../common/html";
import {postHtmlRequest} from "../utils/fetch";
import {compositeDisposable, IDisposable} from "../utils/disposable";

export interface IPopup {
	popup: HTMLElement | undefined;
	openAsync: () => Promise<void>;
	replaceContent: (content: Node | string) => void;
	close: () => void;
}

export interface IPopupOptions {
	readonly closeOnClickOutsidePopup?: boolean;
	readonly onClose?: (popupEl?: HTMLElement) => void;
	readonly onBeforeOpen?: (popupEl?: HTMLElement) => void;
	readonly onBeforeClose?: (popupEl?: HTMLElement) => void;
	readonly onOpen?: (popupEl?: HTMLElement) => void;
	readonly dialogId: string;
	readonly cssClass?: string;
	readonly view: string;
	readonly forceRefresh?: boolean;
	readonly fromCache?: boolean;
	readonly data?: any;
	readonly closeOnEnter?: boolean;
	readonly openClass?: string;
}

let fetchingPopupContent = false;

export async function createPopup(options: IPopupOptions): Promise<IPopup> {
	let isOpen = false;
	let popupEl: HTMLElement;
	const eventDisposables: IDisposable = compositeDisposable(undefined);
	const html = expressQuerySelector(document, "html", true);
	const body = expressQuerySelector(document, "body", true);
	let currentY = window.scrollY;
	const renderedDialogEl = expressQuerySelector(document, ".renderedDialogs", true);

	const {
		closeOnClickOutsidePopup = true,
		onClose,
		onBeforeOpen,
		onBeforeClose,
		onOpen,
		dialogId,
		cssClass,
		view,
		forceRefresh,
		fromCache,
		data,
		closeOnEnter,
		openClass
	} = options;

	const create = (): Promise<HTMLElement | undefined> => {
		let popup: HTMLElement;
		if (dialogId) {
			popup = expressQuerySelector(document, "#" + dialogId, false);
			if (popup) {
				if (forceRefresh) {
					// To keep the same position in the dom tree, so other popups with the same z-inder will remain above or under this popup
					const tempEl = document.createElement("div");
					tempEl.setAttribute("id", "temp-"+dialogId);
					popup.replaceWith(tempEl);
				} else {
					return Promise.resolve(popup);
				}
			}
		}

		if (fetchingPopupContent) return Promise.resolve(undefined);
		fetchingPopupContent = true;

		// Get the html from the server
		const path = `/apicore/dialog/${view}`;
		return postHtmlRequest(path.toLowerCase(), data, {headers: new Headers({'Content-type': 'application/json; charset=utf-8'})}).then(
			res => {
				if (!res) return Promise.resolve(undefined);
				const temp = expressQuerySelector(document, "#temp-" + dialogId, false);
				if (forceRefresh && temp) {
					// To keep the same position in the dom tree, so other popups with the same z-inder will remain above or under this popup
					popup = htmlToElement(res) as HTMLElement;
					temp.replaceWith(popup);
				} else {
					popup = renderedDialogEl.appendChild(htmlToElement(res)) as HTMLElement;
				}

				if (dialogId)
					popup.setAttribute("id", dialogId);

				if (cssClass)
					expressAddClass(popup, cssClass);

				return Promise.resolve(popup);
			}).finally(() => { fetchingPopupContent = false; });
	};
	const replaceContent = (content: Node | string) => {
		eventDisposables.dispose();
		const popupContentEl =  expressQuerySelector<HTMLElement>(popupEl, '.m-popup__content', true);
		popupContentEl.replaceWith(content);
		addOnClose();
	};
	const close = () => {
		if (onBeforeClose) onBeforeClose(popupEl);
		if (openClass)
			expressRemoveClass(popupEl, openClass);
		else
			hide(popupEl);
		isOpen = false;
		// Only do this if there are no other popups open
		const popupEls = expressQuerySelectorAll<HTMLElement>(renderedDialogEl, ".m-popup");
		const otherOpenedPopups = popupEls.filter(p => p.id !== popupEl.id && (p.style.display === 'flex' || p.classList.contains('is-popup-open')));
		if (otherOpenedPopups.length === 0){
			expressRemoveClass(html, "has-open-dialog u-no-scroll");
			expressRemoveClass(body, "u-no-scroll");
			document.documentElement.style.marginTop = "0px";
			window.scroll(0, currentY);
		}
		eventDisposables.dispose();
		if (onClose) window.setTimeout(() => {
			if (!isOpen) onClose(popupEl);
		}, 200);
	};

	const onMouseDownEvent = (ev) => {
		ev.stopPropagation();
		if (ev.target.id
			&& getParents(ev.target, "#" + popupEl.id).length < 1
			&& getParents(ev.target, ".m-popup").length < 1 // support multiple popups
			&& isOpen) close();
	};

	const onKeyDownEvent = (ev) => {
		ev.stopPropagation();
		if (ev.which === 13 || ev.keyCode === 13) if (isOpen) close();
	};

	const onCloseEvent = () => {
		if (isOpen) close();
	};

	const addOnClose = () => {
		const closeButtons = expressQuerySelectorAll<HTMLElement>(popupEl, ".technical-popup-close");
		closeButtons.forEach(closeButton => {
			eventDisposables.add(expressEventListener(closeButton, 'click', onCloseEvent));
		});
		if (closeOnClickOutsidePopup) {
			getParents(popupEl).forEach(el => {
				eventDisposables.add(expressEventListener(el, "mousedown", onMouseDownEvent));
			});
		}
		if (closeOnEnter) eventDisposables.add(expressEventListener(document, "keydown", onKeyDownEvent));
	};

	const openPopupAsync = (): Promise<void> => {
		if (isOpen || !popupEl) return Promise.resolve();
		currentY = window.scrollY;
		if (onBeforeOpen) onBeforeOpen(popupEl);
		if (openClass)
			expressAddClass(popupEl, openClass);
		else
			show(popupEl);
		isOpen = true;
		addOnClose();
		document.documentElement.style.marginTop = "-" + Math.round(currentY) + "px";

		expressAddClass(html, "has-open-dialog u-no-scroll");
		expressAddClass(body, "u-no-scroll");

		if (!openClass) popupEl.style.display = "flex";
		if (isOpen && onOpen) onOpen(popupEl);

		return Promise.resolve();
	};

	if (!popupEl) popupEl = await create();

	return Promise.resolve({
		popup: popupEl,
		openAsync: openPopupAsync,
		close: close,
		replaceContent
	} as IPopup);
}
