export interface IDataLayerProduct {
	currency: string;
	deliveryInfo: string;
	formatImageSrc: string;
	categoryCode: string;
	categoryName: string;
	price: number;
	parentCategories: string;
	pvc: string;
	productCode: string;
	pvcName: string;
}

export function pushEecDetailEvent(newUrl: string, product: IDataLayerProduct) {
	if (window.dataLayer[0].datalayerVersion >= '2022-06') {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'eec.detail',
			ecommerce: {
				detail: {
					actionField: {
					},
					products: [{
						variant: product?.pvc,
						id: product?.productCode,
						name: product?.pvcName,
						category: product?.parentCategories,
						price: product?.price,
						priceWOVat: 0,
						currencyCode: product?.currency,
						isItemJustAddedToCart: false,
						productCategoryName: product?.categoryName,
						productCategoryCode: product?.categoryCode,
						productCategoryUrl: newUrl,
						variantFormatImageUrl: product?.formatImageSrc,
						estDeliveryDate: product?.deliveryInfo,
						isVoucher: false // ?
					}]
				}
			}
		});
	}
}

export function pushComponentEvent(componentName: string) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': componentName +'.component.loaded'
	});
}
