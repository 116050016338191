import { onlyLetters, onlyNumber } from "./string";

export function isValidVatNumber(input: string, countryCode: string) {
	if (vatNumberHasCorrectCountryCode(input, countryCode)) {
		if (countryCode.toLowerCase() !== 'bel') return true;
		const vatNumber = input.match(/\d/g);
		let vatNumberString = '';
		if (vatNumber) vatNumberString = vatNumber.join('');
		const part1 = parseInt(vatNumberString.substring(0, vatNumberString.length - 2), 10);
		const part2 = parseInt(vatNumberString.substring(vatNumberString.length - 2), 10);
		const temp = part1 - parseInt((part1 / 97) + "", 10) * 97;
		return 97 - temp === part2;
	}
	return false;
}

export function vatNumberHasCorrectCountryCode(vatNumber: string, countryCode: string) {
	if (countryCode.toLowerCase() !== 'bel'
		&& countryCode.toLowerCase() !== 'ndl'
		&& countryCode.toLowerCase() !== 'che'
		&& countryCode.toLowerCase() !== 'swe') return true;
	let testVal = '';
	switch (countryCode.toLowerCase()) {
		case 'bel':
			testVal = vatNumber.substring(0, 2);
			if (onlyNumber(testVal)) return true;
			return (onlyLetters(testVal) && testVal.toLowerCase() === 'be');
			break;
		case 'ndl':
			testVal = vatNumber.substring(0, 2);
			if (onlyNumber(testVal)) return true;
			return (onlyLetters(testVal) && testVal.toLowerCase() === 'nl');
			break;
		case 'che':
			testVal = vatNumber.substring(0, 3);
			if (onlyNumber(testVal)) return true;
			return (onlyLetters(testVal) && testVal.toLowerCase() === 'che');
			break;
		case 'swe':
			testVal = vatNumber.substring(0, 2);
			if (onlyNumber(testVal)) return true;
			return (onlyLetters(testVal) && testVal.toLowerCase() === 'se');
			break;
	}
}
