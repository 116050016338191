import {
	expressQuerySelector,
	expressQuerySelectorAll,
	getParentWithClassName
} from "../common/html";
import { createUsps } from "./usp";
import { createCustomSlider, ICustomPhotoSliderItem, ICustomPhotoSliderWithDivSettings } from "./customslider";
import { IRecommendationEventParams, trackRecommendationCartEvent } from "../common/events";
import { stopSkeletonLoading } from "./skeleton";
import { initVideoPlayer } from "./video-player";
import { createProductGrid } from "./product-grid";
import { createHighlightedTiles } from "./highlighted-tiles";
import { createImageDescription } from "./image-description";
import { createTruncate } from "./truncate";
import { createNewsletterSubscribe, createNewsletterUnsubscribe } from "./newsletter";
import { getParameter } from "../utils/querystring";
import {logError} from "../services/logger-service";

export function createHomepageElements() {
	window.showMores = [];
	const uspDesktopEls = expressQuerySelectorAll<HTMLElement>(document, '.technical-usp-desktop');

	// Initialize the usp's
	if (uspDesktopEls) {
		uspDesktopEls.forEach(uspDesktopEl => createUsps(uspDesktopEl));
	}

	window['initProductSliderComponent'] = function initProductSliderComponent(containerEl: HTMLElement) {
		const sliderEl = expressQuerySelector(containerEl, '.technical-slider', true);

		// convert so slider elements
		const sliderElements: ICustomPhotoSliderItem[] = [];
		for (let z = 0, l = sliderEl.children.length; z < l; z++)
			sliderElements.push({ template: sliderEl.children[z] as HTMLElement });

		// settings for slider
		const settings = {
			sliderElements: sliderElements,
			sliderContainerSettings: {
				extraSliderClass: 'm-product-slider__slider-container',
				elementsContainerSettings: {
					extraSliderClass: 'm-product-slider__slider-content',
					slideClicked: (e: Event, settings: ICustomPhotoSliderWithDivSettings) => {
						const slider = getParentWithClassName<HTMLElement>(e.target as Element, 'technical-recommendations');
						if (!slider) return; // only have on click on recommendations

						const currentEl = getParentWithClassName<HTMLElement>(e.target as Element, 'technical-slide');
						if (!currentEl)
							return;

						const anchorEl = expressQuerySelector<HTMLAnchorElement>(currentEl, 'a');
						const label = {
							pageType: containerEl.getAttribute('data-pagetype') || slider.getAttribute('data-pagetype'),
							pageCode: containerEl.getAttribute('data-pageCode') || slider.getAttribute('data-pageCode'),
							pvc: getParameter('pvc', anchorEl.href, false) || ''
						} as IRecommendationEventParams;
						trackRecommendationCartEvent("Recommended product clicked", label);
					}
				},
			},
		} as ICustomPhotoSliderWithDivSettings;

		// create slider
		if (sliderEl.parentElement) createCustomSlider(sliderEl.parentElement, settings);
	};

	// init highlighted tiles
	const highlightedTilesEl = expressQuerySelectorAll<HTMLElement>(document, ".technical-highlighted-tiles");
	highlightedTilesEl.forEach(x => createHighlightedTiles(x));

	const headerComponents = expressQuerySelectorAll<HTMLElement>(document, '.technical-header-component');
	headerComponents.forEach(header => {
		const truncateEl = expressQuerySelector<HTMLElement>(header, '.technical-shave', false);
		if (!truncateEl) return;
		createTruncate(truncateEl, { lines: 2 });
	});

	window['initProductGridComponent'] = function initProductGridComponent(containerEl: HTMLElement) {
		const grid = expressQuerySelector<HTMLElement>(containerEl, ".technical-product-grid", true);
		if (grid) createProductGrid(grid, {}, { refreshOnResize: true });
	};
	window['initMaterialTilesComponent'] = function initMaterialTilesComponent(containerEl: HTMLElement) {
		createImageDescription(containerEl);
		const videoTileEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-video-tile, .technical-video-container');
		videoTileEls.forEach(el => {
			if (!getParentWithClassName(el, 'technical-product-grid-content'))
				initVideoPlayer(el);
		});

		const newsletterSubscribeEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.m-material-tiles .technical-newsletter-subscribe');
		newsletterSubscribeEls.forEach(el => createNewsletterSubscribe(el));

		const newsletterUnSubscribeEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.m-material-tiles .technical-newsletter-unsubscribe');
		newsletterUnSubscribeEls.forEach(el => createNewsletterUnsubscribe(el));
	};

	const productGrids = expressQuerySelectorAll(document, ':not([data-component-callback=initProductGridComponent]) .technical-grid-and-filters');
	productGrids.forEach(x => window['initProductGridComponent'](x));

	const materialTiles = expressQuerySelectorAll(document, ':not([data-component-callback=initMaterialTilesComponent]) .technical-image-description');
	materialTiles.forEach(x => window['initMaterialTilesComponent'](x));

	const videoElements = expressQuerySelectorAll<HTMLElement>(document, '.technical-video');
	videoElements.forEach(x => initVideoPlayer(x));
	stopSkeletonLoading();

	if (window.location.hash) {
		const elContainerEl = expressQuerySelector<HTMLElement>(document, '.technical-homepage-elements', true);
		const hashEl = expressQuerySelector<HTMLElement>(elContainerEl, window.location.hash, false);
		if (hashEl)
			hashEl.scrollIntoView({behavior: 'smooth'});
		else
			logError("Can't scroll to item with id = " + window.location.hash + " as it can't be found on the page", window.location.href, undefined, undefined, undefined, "Content");
	}
}
