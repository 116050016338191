﻿import {expressQuerySelector} from "../common/html";
import {debounce} from "../utils/debounce";

export function createScrollToTop()
{
	const scrollToTopEl = expressQuerySelector(document, ".technical-scroll-to-top", false);
	const init = () => {
		const wobblerEl = expressQuerySelector(document, ".m-wobbler", false);
		let fade;
		let displayNone;
		if (wobblerEl) scrollToTopEl!.classList.add('m-scroll-to-top--has-wobbler');

		const isIOS = (): boolean => {
			const iosQuirkPresent = () => {
				const audio = new Audio();
				audio.volume = 0.5;
				return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
			};
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
			const isAppleDevice = navigator.userAgent.indexOf('Macintosh') !== 0;
			const isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13
			return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
		};

		const showBtn = (num: number) => {
			if (document.documentElement.scrollTop >= num ) {
				clearTimeout(fade);
				fade = setTimeout( function() {
					clearTimeout(displayNone);
					scrollToTopEl!.classList.remove('u-hide');
					setTimeout(function () {
						scrollToTopEl!.classList.remove('m-scroll-to-top--invisible');
					}, 100);
				}, 100);
			} else {
				clearTimeout(fade);
				fade = setTimeout( function() {
					scrollToTopEl!.classList.add('m-scroll-to-top--invisible');
					displayNone = setTimeout(function () {
						scrollToTopEl!.classList.add('u-hide');
					}, 500);
				}, 100);
			}
		};

		window.addEventListener('scroll', () => { showBtn(20); });

		if (scrollToTopEl) {
			scrollToTopEl.addEventListener("click", () => {
				window.scrollTo({ top: 0, behavior: "smooth" });
				if (isIOS()) debounce(() => { window.scrollTo({ top: 0, behavior: "smooth" }); }, 100);
			});
		}
	};

	scrollToTopEl && init();
}
